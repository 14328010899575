import React from 'react'
import classes from './Classes'
import './Card.less'

const Card = props => {
  const url = props.card?.articleUrl || props.card?.cardUrl
  const img = props.card?.articleImg || props.card?.cardImg
  const title = props.card?.articleTitle || props.card?.cardSuperHeadline

  return (
    <div className={classes.cardContainer}>
      <div className={classes.card}>
        <a href={url} className="u-linkClean">
          <div className="card-image">
            {img && <img src={img} className="img-fit" />}
          </div>

          <div className={classes.cardContent}>
            <p className={classes.cardSuper}>{title}</p>

            <h3 className={classes.cardHeading}>{props.card?.cardHeading}</h3>

            <div className={classes.cardBottomGrid}>
              <p className="u-textColorLight">
                {props.card?.cardDescription.replace(/(<([^>]+)>)/gi, '')}
              </p>
            </div>
          </div>
        </a>
      </div>
    </div>
  )
}

export default Card
