import React, { useState } from 'react'
import './index.css'

function Caption(data) {
  const [modalOpen, toggleModal] = useState(false)
  const { caption, title } = data.content

  const Modal = () => {
    return (
      <div
        className="Modal Modal--md Modal--sm"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDesc"
      >
        <section className="Modal-window">
          <header className="Modal-header">
            <h3 className="Modal-title" id="modalTitle">
              {title}
            </h3>
            <button onClick={() => toggleModal(false)} className="Modal-icon">
              &#x2297;
              <span className="u-hiddenVisually">Close this dialog</span>
            </button>
          </header>
          <div className="Modal-content Content" id="modalDesc">
            <p>{caption}</p>
          </div>
        </section>
      </div>
    )
  }

  return (
    <div className="caption-container u-adel-reset">
      <a className="caption-icon" onClick={() => toggleModal(!modalOpen)}>
        {title}
      </a>
      {modalOpen && <Modal />}
    </div>
  )
}

export default Caption
