/* eslint-disable semi */

const dust = { helpers: {}, filters: {} }

dust.helpers.queryMark = function (context) {
  var content = context.content

  // line feeds will cause an error for highlighting but are unnecessary in any case
  if (content) {
    content = content && content.replace(/\n/g, '')
    var queryTerms = context.queryTermsRegExp
    if (queryTerms) {
      var TERMS = new RegExp('(\\b[^\\s]*?' + queryTerms + '[^\\s]*?\\b)', 'gi')
      content = content && content.replace(TERMS, '<mark>$&</mark>')
    }
  } else {
    content = ''
  }

  return content
}

dust.filters.ph = function (phoneNo) {
  return phoneNo.trim().replace(/\s+?/g, '-')
}

dust.filters.nseg = function (name) {
  return name.toLowerCase().replace(/\s+?/g, '.') + '/'
}

dust.filters.qs = function (query) {
  return query.replace(/\s+/g, '+')
}

dust.filters.ramps = function (string) {
  return string.replace(/&amp;/g, '&')
}

dust.helpers.resolveStringItems = function (itemsArray) {
  // simply return the first valid item
  var item

  for (var i = 0; i < itemsArray.length; i++) {
    item = itemsArray[i]

    if (typeof item === 'string' && item.length > 0) {
      return item
    }
  }
}

dust.helpers.resolvedUrl = function (context) {
  var items = [context.omniUrl || '', context.url || '']

  return dust.helpers.resolveStringItems(items)
}

dust.helpers.resolvedTitle = function (context) {
  var items = [context.omniTitle || '', context.title || '']

  return dust.helpers.resolveStringItems(items)
}

dust.helpers.resolvedDescription = function (context) {
  var items = [context.omniDescription || '', context.description || '']

  var resolvedDescription = ''
  try {
    resolvedDescription =
      dust.helpers.resolveStringItems(items).replace(/null/gi, '') || ''
    if (resolvedDescription) {
      resolvedDescription = /(^skip to main|\\u000)/i.test(resolvedDescription)
        ? ' '
        : resolvedDescription
    }
  } catch (e) {}

  return resolvedDescription
}

dust.helpers.resolvedCategory = function (context) {
  function filterCategory(category) {
    var VALTEST = /(null|,)/i

    if (typeof category !== 'string') return ''
    if (category.length > 40) return ''
    if (VALTEST.test(category)) return ''

    return category
  }

  return filterCategory(context.category)
}

dust.filters.duc = function (string) {
  return decodeURIComponent(string)
}

dust.filters.enc = function (string) {
  return encodeURIComponent(string)
}

export default dust
