const classes = {
  // tab bar
  tabComponent:
    'Tab-component u-paddingVert10gu u-sizeConstrained u-posRelative',
  tabContainer: 'Tabs-container u-flex u-sm-flexJustifyCenter',
  tabsHoriz:
    'Tabs horizontal u-posRelative u-marginBottom6gu u-sm-flex u-sm-flexCol u-sm-size1of2 u-flexNoWrap u-decoBorderLight u-decoBorderResetTop',
  tab: 'u-bgColorTransparent u-textPrimary u-textNoTransform u-inlineBlock u-posRelative u-sm-marginRight0 u-padding2gu',
  // tab panels
  tabPanels: 'Tab-panels u-paddingVert2gu u-marginBottom6gu',
  tabPanel: 'Tab-panel',
  tabContent: 'Tab-content',
  cardGrid: 'card-grid-component u-paddingTop2gu',
  grid: 'Grid u-sizeFull u-sm-flexJustifyCenter',
  countText: 'u-textPrimary u-padding3gu u-textCenter',
  // cards
  cardContainer: 'u-flex u-md-size1of3 u-lg-size1of3 u-padding2gu',
  card: 'Card u-sizeFull u-posRelative u-bgColorWhite',
  cardContent: 'Content card-content u-paddingHoriz4gu',
  cardSuper:
    'card-super u-paddingTop4gu u-textSizeMsrm1 u-textBold u-textTertiary u-textUppercase u-textColorLight',
  cardHeading:
    'card-heading u-marginTop1gu u-paddingTop0 u-paddingBottom3gu u-textSecondary u-textOpen',
  cardBottomGrid: 'Content u-paddingBottom5gu read-time grid',
  cardBottom: 'card-bottom grid u-posAbsolute u-paddingVert6gu u-sizeFull',
  cardViewArticle: 'Content view-article u-textCenter',
  video: 'video-js video-container ty-message'
}

export default classes
