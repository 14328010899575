import '@ampcore/adel-shim/dist/adel.amp.next.css'
import '@ampcore/adel-shim/dist/themes/theme.amp.next.css'

import 'core-js'
import 'regenerator-runtime/runtime'

import '@webcomponents/webcomponentsjs/webcomponents-loader.js'
import '@webcomponents/webcomponentsjs/custom-elements-es5-adapter.js'

import './common/loader'
import './common/analytics'
import './common/platform'
import './common/get-script-nonce.js'
import './components/IosBanner/index.js'
import './components/Audio/index.js'
import './components/Table/index.js'
import './components/Image/index.js'
import './components/Accordion/index.js'
import './components/Carousel/index.js'
import './components/OneTrust/index.js'
import './components/Rollover/index.js'
import './components/CardList/index.js'
import './components/BackToTop/index.js'
import './components/Interstitial/sitewideInterstitial.js'
import 'details-element-polyfill'

const PUBLIC_PATH_KEY = 'AMPSITEPATH'
/* eslint-disable-next-line camelcase, no-undef */
__webpack_public_path__ =
  PUBLIC_PATH_KEY in window
    ? window[PUBLIC_PATH_KEY]
    : '/webfiles/latest/assets/ampsite/'
