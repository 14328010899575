import React, { useRef } from 'react'
import xss from 'xss'
import './commitments.css'

const Commitments = props => {
  const { content, testId } = props
  const tabRefs = useRef({})
  const buttonRefs = useRef({})
  const mobileButtonRefs = useRef({})
  const previousRefs = useRef({})
  const nextRefs = useRef({})
  function tabClick(index) {
    tabRefs.current[index].click()
    tabRefs.current[index].focus()
    tabRefs.current[index].scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center'
    })
  }
  function onKeydown(event, index) {
    if (
      !(
        event.key === 'Tab' &&
        event.shiftKey &&
        event.target.tagName === 'BUTTON' &&
        index === 0
      )
    ) {
      switch (event.key) {
      case 'ArrowLeft':
        tabPrevious(index)
        break
      case 'ArrowRight':
        tabNext(index)
        break
      case 'Tab':
        if (event.target.tagName === 'BUTTON') {
          event.preventDefault()
          buttonRefs.current[index].focus()
          mobileButtonRefs.current[index].focus()
        } else if (index !== content.tab.length - 1) {
          event.preventDefault()
          tabClick(index + 1)
        }
        if (event.shiftKey) {
          if (event.target.tagName === 'BUTTON') {
            if (index > 0) {
              event.preventDefault()
              tabClick(index - 1)
              buttonRefs.current[index - 1].focus()
              mobileButtonRefs.current[index - 1].focus()
            }
          } else {
            event.preventDefault()
            tabClick(index)
          }
        }
        break
      }
    }
  }
  function tabPrevious(index) {
    if (index === 0) previousRefs.current[index].disabled = true
    else tabClick(index - 1)
  }
  function tabNext(index) {
    if (index === content.tab.length - 1) {
      nextRefs.current[index].disabled = true
    } else tabClick(index + 1)
  }
  if (!content.tab || content.tab.length < 2 || content.tab.length > 4) {
    console.error('Invalid commitments tab length')
    return null
  }
  return (
    <div className="u-adel-reset">
      <div className="Commitments u-posRelative">
        <h2 className="Type-2 u-sizeConstrained u-flex u-flexJustifyCenter u-textCenter u-paddingBottom10gu u-textPrimary u-textColorNeutralGrey">
          {content.headline}
        </h2>
        <tab-component>
          <div className="Tabs-container Tab-items-height u-sizeConstrained Grid u-marginCenter">
            <div className="Tabs Content u-flex u-flexRow u-flexJustifyCenter">
              {content.tab?.map((tab, index) => (
                <div className="Tab-item u-paddingHoriz2gu" key={index}>
                  <button
                    id={tab.buttonId}
                    role="tab"
                    aria-controls={`${tab.identifier}-${index}`}
                    tab-idx={`${tab.identifier}-${index}`}
                    className="Commitments-tab u-sm-marginTop1gu"
                    data-analytics={tab.analytics}
                    ref={el => (tabRefs.current[index] = el)}
                    onFocus={() => {
                      tabClick(index)
                    }}
                    onKeyDown={event => {
                      onKeydown(event, index)
                    }}
                  >
                    <svg
                      className="Button-icon u-paddingBottom2gu u-block u-marginCenter"
                      aria-hidden="true"
                    >
                      <use
                        href={tab.icon}
                        data-testid={
                          testId ? `${testId}-${index}-tab-icon` : null
                        }
                      />
                    </svg>
                    {tab.title}
                  </button>
                </div>
              ))}
            </div>
          </div>
          <div className="Tab-panels u-marginBottom6gu">
            {content.tab?.map((tab, index) => (
              <tab-inner
                id={`${tab.identifier}-${index}`}
                role="tabpanel"
                className="Tab-panel"
                key={index}
              >
                <div className="u-sizeConstrained">
                  <div
                    className="Tab-content Content Grid u-Desktop u-sm-hidden u-md-hidden u-flexJustifyCenter"
                    key={content.index}
                  >
                    <div
                      className={
                        'Image-box u-size1of2' +
                        (tab.location === 'right' ? ' u-flexOrderLast' : '')
                      }
                    >
                      <img
                        className="Commitments-image"
                        src={tab.content.imageSrc}
                        alt={tab.content.imageAlt}
                        data-testid={testId ? `${testId}-${index}-image` : null}
                      />
                    </div>
                    <div
                      className={`Commitments-body Content u-paddingVert9gu u-paddingHoriz8gu u-size1of2 u-bgColor${tab.bgcolor}`}
                      data-testid={testId ? `${testId}-${index}-content` : null}
                    >
                      <h3
                        className="u-textColorNeutralGrey u-textBold"
                        data-testid={
                          testId ? `${testId}-${index}-heading` : null
                        }
                      >
                        {tab.content.heading}
                      </h3>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: xss(tab.content.body)
                        }}
                        data-testid={testId ? `${testId}-${index}-body` : null}
                      ></p>
                      <div className="u-paddingTop4gu">
                        <a
                          className={`Commitments-button-${index} Button Button--primary`}
                          role="button"
                          href={tab.content.link}
                          data-analytics={tab.analytics}
                          data-testid={
                            testId ? `${testId}-${index}-link` : null
                          }
                          ref={el => (buttonRefs.current[index] = el)}
                          onKeyDown={event => {
                            onKeydown(event, index)
                          }}
                        >
                          {tab.content.linkText}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="Tab-content Content Grid u-Mobile u-lg-hidden"
                  key={content.index}
                >
                  <div
                    className={`Commitments-body Content u-paddingTop8gu u-md-paddingHoriz6gu u-paddingHoriz4gu u-bgColor${tab.bgcolor}`}
                  >
                    <h2 className="u-textColorNeutralGrey u-textBold">
                      {tab.content.heading}
                    </h2>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: xss(tab.content.body)
                      }}
                    ></p>
                    <div className="u-paddingVert6gu">
                      <a
                        className="Button Button--primary"
                        href={tab.content.link}
                        data-analytics={tab.analytics}
                        ref={el => (mobileButtonRefs.current[index] = el)}
                        onKeyDown={event => {
                          onKeydown(event, index)
                        }}
                      >
                        {tab.content.linkText}
                      </a>
                    </div>
                  </div>
                </div>
                <div className="Carousel-toggle u-sizeConstrained u-marginLeftM2gu u-flex u-flexAlignItemsEnd u-Mobile u-lg-hidden u-md-hidden u-paddingVert2gu">
                  <button
                    id="carousel-previous"
                    aria-hidden="false"
                    className="Button Button--secondary Button--secondary--action-blue Button--icon Button--icon--stroke u-marginRight3gu"
                    ref={el => (previousRefs.current[index] = el)}
                    onClick={() => {
                      tabPrevious(index)
                    }}
                    disabled={index === 0}
                  >
                    <span aria-hidden="true">&#xe314;</span>
                    <span className="u-hiddenVisually">Slide Left</span>
                  </button>
                  <button
                    id="carousel-next"
                    aria-hidden="false"
                    className="Button Button--secondary Button--secondary--action-blue Button--icon Button--icon--stroke u-marginRight3gu"
                    ref={el => (nextRefs.current[index] = el)}
                    onClick={() => {
                      tabNext(index)
                    }}
                    disabled={index === content.tab.length - 1}
                  >
                    <span aria-hidden="true">&#x25b9;</span>
                    <span className="u-hiddenVisually">Slide Right</span>
                  </button>
                  <p className="u-textColorActionBlue u-paddingLeft0">
                    {index + 1} / {content.tab.length}
                  </p>
                </div>
              </tab-inner>
            ))}
          </div>
        </tab-component>
      </div>
    </div>
  )
}

export default Commitments
