import React from 'react'

import ContactFormSecureSite from './ContactFormSecureSite.jsx'
import ContactFormBrokerage from './ContactFormBrokerage.jsx'
import ContactFormNFLPA from './ContactFormNFLPA.jsx'
import ContactFormCCPA from './ContactFormCCPA.jsx'
import ContactFormIRA from './ContactFormIRA.jsx'
import ContactFormAPRI from './ContactFormAPRI.jsx'
import TaxAndStatement from './TaxAndStatement.jsx'
import { sendEmail, CONSTs } from '../views/utils'
import { v4 } from 'uuid'
import 'whatwg-fetch'

/*
 * Form component
 */
export default class Form extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}

    this.renderTemplate = this.renderTemplate.bind(this)
    this.sendEmailToService = this.sendEmailToService.bind(this)
    this.dowloadTaxForm = this.dowloadTaxForm.bind(this)
  }

  /*
   * Email API
   */
  sendEmailToService(data, emailInfo, reverseCallback, showEmailIntro = false) {
    emailInfo.fromAddress = data.Email
    if (
      typeof data.ChangeFromAddress !== 'undefined' &&
      data.ChangeFromAddress === true
    ) {
      emailInfo.fromAddress = emailInfo.fromEmailAddress
      delete data.ChangeFromAddress
    }
    if (typeof data['Client ID'] !== 'undefined' && data['Client ID'] === '') {
      delete data['Client ID']
    }
    const formEntries = Object.entries(data)
      .map(entry => entry.join(': ') + '<br />')
      .join('')
    const emailContent =
      showEmailIntro && emailInfo.emailIntro
        ? `${emailInfo.emailIntro} ${formEntries}`
        : formEntries
    // To send email
    emailInfo.toAddress = emailInfo.toEmailAddress
    return sendEmail(emailContent, emailInfo, reverseCallback)
  }
  /*
   * Tax and Statement CSV download
   */
  dowloadTaxForm(data, emailInfo, reverseCallback) {
    data.url = emailInfo.emailApi

    const taxPayLoad = {
      pckId: data.packageid,
      docId: data.documentid
    }
    const filename = () => {
      const id = v4()
      return `${id}.csv`
    }
    const body = JSON.stringify(taxPayLoad)

    fetch(data.url, {
      method: 'POST',
      headers: {
        Accept: '*.*/*.*',
        'Content-Type': 'application/json',
        'Content-Length': body.length,
        Connection: 'keep-alive'
      },
      body: body
    }).then(
      response => {
        // https://medium.com/@nerdyman/prompt-a-file-download-with-the-content-disposition-header-using-fetch-and-filesaver-8683faf565d0
        // https://github.com/eligrey/FileSaver.js  - library for this

        if (response.ok) {
          response.blob().then(data => {
            if (window.navigator.msSaveBlob) {
              let blob = new Blob([data], {
                type: 'text/csv;charset=utf8;'
              })
              window.navigator.msSaveBlob(blob, filename())
            } else {
              let blob = new Blob([data], {
                type: 'text/csv;charset=utf8;'
              })
              let CSVDownloadLink = document.createElement('a')
              let CSVFile = URL.createObjectURL(blob)
              CSVDownloadLink.setAttribute('target', '_blank')
              CSVDownloadLink.setAttribute('href', CSVFile)
              CSVDownloadLink.setAttribute('download', filename())
              CSVDownloadLink.style.visibility = 'hidden'
              document.body.appendChild(CSVDownloadLink)
              CSVDownloadLink.click()
              document.body.removeChild(CSVDownloadLink)
            }
          })
          return reverseCallback(response, 'success')
        } else {
          return reverseCallback(response, 'failure')
        }
      },
      response => {
        return reverseCallback(response, 'failure')
      }
    )
  }

  /*
   * Render form template based on the freemarker conditions
   */

  // TODO: incorporate the consumption of optional html into the react component

  renderTemplate() {
    const {
      props: {
        content: { formTemplate }
      }
    } = this
    if (formTemplate && formTemplate.name) {
      switch (formTemplate.name) {
      case CONSTs.CONTACTSECURESITE:
        return (
          <ContactFormSecureSite
            content={this.props.content}
            name={CONSTs.CONTACTSECURESITE}
            callback={this.sendEmailToService}
          />
        )
      case CONSTs.CONTACTBROKERAGE:
        return (
          <ContactFormBrokerage
            content={this.props.content}
            name={CONSTs.CONTACTBROKERAGE}
            callback={this.sendEmailToService}
          />
        )
      case CONSTs.CONTACTNFLPA:
        return (
          <ContactFormNFLPA
            content={this.props.content}
            name={CONSTs.CONTACTNFLPA}
            callback={this.sendEmailToService}
          />
        )
      case CONSTs.CONTACTCCPA:
        return (
          <ContactFormCCPA
            content={this.props.content}
            name={CONSTs.CONTACTCCPA}
            callback={this.sendEmailToService}
          />
        )
      case CONSTs.CONTACTIRA:
        return (
          <ContactFormIRA
            content={this.props.content}
            name={CONSTs.CONTACTIRA}
            callback={this.sendEmailToService}
          />
        )
      case CONSTs.TAXSTATEMENT:
        return (
          <TaxAndStatement
            content={this.props.content}
            name={CONSTs.TAXSTATEMENT}
            callback={this.dowloadTaxForm}
          />
        )
      case CONSTs.CONTACTAPRI:
        return (
          <ContactFormAPRI
            content={this.props.content}
            name={CONSTs.CONTACTAPRI}
            callback={this.sendEmailToService}
          />
        )
      }
    }
  }

  render() {
    return <div>{this.renderTemplate()}</div>
  }
}
