import React, { Fragment } from 'react'
import { MicrositeHeader } from '../../MicrositeHeaderComponent/index'

import '../../MicrositeHeaderComponent/index.css'

export default class Header extends React.Component {
  render() {
    const { headerImg, headerImgSm } = this.props.content

    return (
      <Fragment>
        <MicrositeHeader imageLarge={headerImg} imageSmall={headerImgSm} />
      </Fragment>
    )
  }
}
