import { getEnvironment } from '../../common/environment/index'
import { htmlToPdf } from './htmlToPdf.js'
import getDownloadCSS from '../../services/getDownloadCss.js'

function routePath(match, before, path, after) {
  let result = match

  if (path.charAt(0) === '/') {
    const origin =
      getEnvironment() === 'localhost'
        ? 'https://www.qa.ameriprise.com'
        : window.location.origin

    result = before + origin + path + after
  }

  return result
}

function routePath2(match, before, path, after) {
  let result = match

  if (path.charAt(0) === '/') {
    const origin = 'https://www.ameriprise.com/'

    result = before + origin + path + after
  }

  return result
}

function routePaths(html) {
  return html
    .replace(/(src=")([^"]+)(")/g, routePath)
    .replace(/(href=")([^"]+)(")/g, routePath)
    .replace(/(url\(")([^")]+)(")/g, routePath2)
    .replace(/(url\()([^)]+)(\))/g, routePath2)
}

export async function getDownloadHtml(body) {
  const AMPVENDORCSS = window.AMPSITEPATH + 'vendor.css'
  const cssFirst = await getDownloadCSS(AMPVENDORCSS)
  const html = `
    <html>
      <head>
        <meta charset="utf-8">
        <title>IRA Rollover Evaluator</title>
        <style>${cssFirst}</style>
        <style>
        /* Override a few adel stylings which don't display as desired in pdf view */
        /* TODO:  Some of these will become redundant in a future ADEL release.  Delete if no longer needed */
        .page-break { page-break-after: always; }
        .u-adel-reset .Content ol, .u-adel-reset .Content ul { list-style-type: disc; }
        .u-adel-reset .Icon--after::after { visibility: hidden; }
      }
        </style>
      </head>
      <body class="u-adel-reset amp-scope">
        <div class="Content u-posRelative">
          ${body}
        </div>
      </body>
    </html>
    `
  return routePaths(html)
}

export default async function download(body) {
  const html = await getDownloadHtml(body)
  return htmlToPdf(html)
}

export async function emailForm(body) {
  const html = await getDownloadHtml(body)
  const escapeChars = {
    '‘': '&lsquo;',
    '’': '&rsquo;',
    '“': '&ldquo;',
    '”': '&rdquo;',
    '©': '&copy;',
    '®': '&reg;',
    '™': '&trade;',
    '¼': '&frac14;',
    '½': '&frac12;',
    '¾': '&frac34;',
    '¢': '&cent;',
    '£': '&pound;',
    '¥': '&yen;',
    '€': '&euro;'
  }
  var regexString = '['
  for (var key in escapeChars) {
    regexString += key
  }
  regexString += ']'
  const regex = new RegExp(regexString, 'g')
  return html.replace(regex, function (m) {
    return escapeChars[m]
  })
}
