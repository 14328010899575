/*
 * Fetch Service
 */
import { postDataToService } from '../../../common/fetch'

/*
 * Mapping Form data as follows
 * First Name: Lorem
 * Last Name: Ipsum
 */
export const formSelectedData = (formData, template) => {
  let returnObj = ''
  if (template && template.name) {
    switch (template.name) {
    case CONSTs.CONTACTSECURESITE:
      returnObj = getFormData(formData)
      break
    case CONSTs.CONTACTBROKERAGE:
      returnObj = getFormData(formData)
      break
    case CONSTs.CONTACTNFLPA:
      returnObj = getNFLPAFormData(formData)
      break
    case CONSTs.CONTACTCCPA:
      returnObj = getFormCcpaData(formData)
      break
    case CONSTs.CONTACTIRA:
      returnObj = getFormIraData(formData, template)
      break
    case CONSTs.TAXSTATEMENT:
      returnObj = formData
      break
    case CONSTs.CONTACTAPRI:
      returnObj = getAPRIFormData(formData)
      break
    default:
      returnObj = getFormData(formData)
      break
    }
  }
  return returnObj
}

/*
 *  Secure site & Brokerage form data
 */
export const getFormData = ({
  firstname,
  lastname,
  emailaddress,
  street,
  city,
  state,
  zipcode,
  inquiry
}) => {
  return {
    'First name': firstname,
    'Last name': lastname,
    Email: emailaddress,
    Street: street,
    City: city,
    State: state,
    'Zip code': zipcode,
    Message: inquiry
  }
}

export const getNFLPAFormData = ({
  firstname,
  lastname,
  emailaddress,
  phone,
  teamname,
  inquiry
}) => {
  return {
    'First name': firstname,
    'Last name': lastname,
    Email: emailaddress,
    Phone: phone,
    'Team name': teamname,
    Message: inquiry
  }
}

export const getAPRIFormData = ({
  firstname,
  lastname,
  emailaddress,
  phone
}) => {
  return {
    'First name': firstname,
    'Last name': lastname,
    Email: emailaddress,
    Phone: phone
  }
}
/*
 *  CCPA form data
 * ChangeFromAddress is used to indicate that from email addres is from resource bundle instead of from form input field
 */
export const getFormCcpaData = ({
  resident,
  secureSite,
  relationship,
  requesttype,
  accountid,
  employeeid,
  firstname,
  lastname,
  clientid,
  emailaddress,
  phone,
  street,
  city,
  state,
  zipcode
}) => {
  return {
    'CA Resident': resident,
    Relationship: relationship,
    'Request Type/s': requesttype,
    'First name': firstname,
    'Last name': lastname,
    'Client ID': clientid,
    Email: emailaddress,
    ChangeFromAddress: true,
    Phone: phone,
    Street: street,
    City: city,
    State: state,
    'Zip code': zipcode,
    'Registered on Secure Site': secureSite,
    'Account ID': accountid || 'n/a',
    'Employee ID': employeeid || 'n/a'
  }
}

/*
 *  IRA form data
 */
export const getFormIraData = (
  { fullname, emailaddress, phone, inquiry, resultDownload },
  { advisoremail }
) => {
  const message = '<html><body>' + inquiry + resultDownload + '</body></html>'
  return {
    'Full Name': fullname,
    Email: emailaddress,
    Phone: phone,
    Message: message
  }
}

/*
 *  Set Dom
 */
export const setInnerHtml = args => {
  return { __html: args }
}

/*
 *  Static Text
 */
export const CONSTs = {
  SUCCESS: 'success',
  FAILURE: 'failure',
  CROSSDOMAIN: 'cross domain',
  CONTACTSECURESITE: 'Secure Site Contact',
  CONTACTBROKERAGE: 'Brokerage',
  CONTACTNFLPA: 'NFLPA',
  CONTACTCCPA: 'California Privacy Inquiry',
  CONTACTIRA: 'IRA Rollover Share',
  CONTACTAPRI: 'APRI',
  TAXSTATEMENT: 'Tax Statement',
  ANALYTICSSUCCESSMSG: 'notification-success',
  ANALYTICSFAILURESMSG: 'notification-fail',
  TAXDOWNLOADFORMBTN: 'tax-download-form'
}

/*
 *  Send email
 */
export const sendEmail = (serverData, emailInfo, formCallback) => {
  if (!serverData || !emailInfo) {
    return false
  }
  const receivedData = {
    url: emailInfo.emailApi,
    data: {
      type: 'email',
      data: {
        from: emailInfo.fromAddress,
        to: [emailInfo.toAddress],
        subject: emailInfo.subject,
        body: serverData
      }
    }
  }
  /*
   * Send form data to the postDataToService method in utils under common/fetch
   */
  postDataToService(receivedData)
    .then(response => {
      if (response) {
        const message = response.message && response.message.toUpperCase()
        if (message === 'OK' && response.code === '200') {
          return formCallback(response, CONSTs.SUCCESS)
        } else {
          return formCallback(response, CONSTs.FAILURE)
        }
      } else {
        return formCallback(response, CONSTs.FAILURE)
      }
    })
    .catch(error => {
      return formCallback(error, CONSTs.FAILURE)
    })
  return false
}
