import React from 'react'
import classes from '../helpers/PersonalizedClasses'
import './Personalized.less'
import Icon from '../helpers/Icon'
import Image from '../helpers/Image'
import ActionList from '../helpers/ActionList'
import Analytics from '../../../SiteNav/analytics.js'
import { getAdvisorLinkWithCookie } from '../../../../ui/AdvisorQueryParameter/advisors-query-param'

function notifyAnalytics(type, message) {
  // Analytics Tagging
  try {
    const buttonAnalytics = new Analytics()
    buttonAnalytics.ping(message, type)
  } catch (e) {
    console.error(e)
  }
}

function advisorSearchLink(template) {
  function handleClick(e) {
    // allow default functionality
    notifyAnalytics('Personalized DAC', 'Search for a new advisor')
  }

  const url = getAdvisorLinkWithCookie('https://www.ameripriseadvisors.com/')
  if (template === 'nextgen2024') {
    return (
      <a href={url} onClick={handleClick}>
        {'keep searching'}
      </a>
    )
  }
  return (
    <a href={url} onClick={handleClick}>
      {'Search for a new advisor'}
    </a>
  )
}

function ConsultationRequestLink(props) {
  function handleClick(e) {
    // allow default functionality
    notifyAnalytics('Personalized DAC', 'Request consultation')
  }
  if (props.data.url) {
    const url = props.data.url + '?openFormModal=request-contact'
    const urlWithAuth = getAdvisorLinkWithCookie(url)
    return (
      <a
        className={props.classes}
        href={urlWithAuth}
        onClick={handleClick}
        target="_blank"
        rel="noreferrer"
      >
        Request a consultation
      </a>
    )
  }
}

const EmailListItem = props => {
  return (
    <li className="List-item u-sizeFull u-flex u-flexAlignItemsCenter u-marginBottom1gu u-md-size6of12 u-md-marginBotton2gu u-sm-marginBottom2gu">
      <span
        className="adel-material-symbols u-textColorActionBlue u-marginRight2gu"
        role="img"
        title="happy"
        aria-label="happy"
      >
        mail
      </span>
      <a
        href={`mailto:${props.email}`}
        onClick={() => notifyAnalytics('Personalized DAC', 'Email')}
      >
        {props.email}
      </a>
    </li>
  )
}

const PhoneNumberListItem = props => {
  return (
    <li className="List-item u-sizeFull u-flex u-flexAlignItemsCenter u-marginBottom1gu u-md-size6of12 u-md-marginBotton2gu u-sm-marginBottom2gu">
      <span
        className="adel-material-symbols u-textColorActionBlue u-marginRight2gu"
        role="img"
        title="phone"
        aria-label="phone"
      >
        phone
      </span>
      <a
        href={`tel:${props.phonenumber}`}
        onClick={() => notifyAnalytics('Personalized DAC', 'Call')}
      >
        {props.phonenumber}
      </a>
    </li>
  )
}

const WebsiteListItem = props => {
  return (
    <li className="List-item u-sizeFull u-flex u-flexAlignItemsCenter u-marginBottom1gu u-md-size6of12 u-md-marginBotton2gu u-sm-marginBottom2gu">
      <span
        className="adel-material-symbols u-textColorActionBlue u-marginRight2gu"
        role="img"
        title="new_window"
        aria-label="new_window"
      >
        new_window
      </span>
      <a href={props.url} target="_blank" rel="noreferrer">
        Advisor website
      </a>
    </li>
  )
}

export const PersonalizedNextGenTemplate = props => {
  const { testId } = props
  const { content } = props.data

  return (
    <div
      id="personalizedDac"
      className="Advisor--color Advisor--personalizedView u-bgSizeCover u-bgColorMidnightBlue"
      data-testid={testId ? `${testId}-container` : null}
      style={{
        backgroundImage: `url(${window.AMPCOMPATH}images/facets/hero-facets-dark-midnight-blue.png)`
      }}
    >
      <div className="u-sizeConstrained u-paddingVert6gu">
        <div className="Content--reversed u-textCenter u-marginBottom4gu">
          <h2 className="u-textSecondary u-textSizeMsrp4 u-marginBottom1gu">
            Take the first step toward a more confident financial future.
          </h2>
          <p>{`Connect with ${props.data.data.name} for a complimentary initial consultation today.`}</p>
        </div>
        <div className="Card">
          <div className="AdvisorPersonalized AdvisorPersonalized--nextGen2024 Grid Card-body u-padding4gu">
            <div className="u-size8of12 u-flex u-flexRow u-paddingRight4gu u-md-sizeFull u-sm-sizeFull u-sm-flexCol u-md-paddingRight0 u-sm-paddingRight0">
              <div className="AdvisorPersonalized-image u-marginRight4gu u-sm-marginRight0 u-sm-marginBottom4gu">
                <Image data={props.data.data}></Image>
              </div>
              <div className="AdvisorPersonalized-info">
                <h3
                  className="AdvisorPersonalized-name u-textBold u-marginBottom2gu u-textSizeMsrp2"
                  data-testid={testId ? `${testId}-name` : null}
                >
                  {props.data.data.name}
                </h3>
                {props.data.data.tagline && (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: props.data.data.tagline
                    }}
                  ></p>
                )}
                <div className="u-md-hidden u-sm-hidden">
                  <ConsultationRequestLink
                    data={props.data.data}
                    testId={testId}
                    classes="Button Button--primary AdvisorPersonalized-button u-marginTop4gu u-marginBottom2gu u-md-sizeFull u-sm-sizeFull"
                  />
                  <p>
                    Or, {advisorSearchLink(content.template)} for a financial
                    advisor.
                  </p>
                </div>
              </div>
            </div>
            <div className="u-lg-hidden u-size12of12">
              <ConsultationRequestLink
                data={props.data.data}
                testId={testId}
                classes="Button Button--primary AdvisorPersonalized-button u-marginTop4gu u-marginBottom2gu u-md-sizeFull u-sm-sizeFull"
              />
              <p>
                Or, {advisorSearchLink(content.template)} for a financial
                advisor.
              </p>
            </div>
            <div className="u-size4of12 u-paddingLeft4gu u-borderLeft u-md-sizeFull u-md-paddingLeft0 u-md-paddingTop4gu u-md-borderLeft0 u-md-marginTop4gu u-md-borderTop u-sm-sizeFull u-sm-paddingLeft0 u-sm-paddingTop3gu u-sm-marginTop4gu u-sm-borderLeft0 u-sm-borderTop">
              <div className="u-textPrimary u-textColorNeutralGrey u-textBold u-marginBottom2gu u-sm-hidden">
                Contact
              </div>
              <ul className="AdvisorPersonalized-contactInfo List Grid u-sm-hidden">
                {props.data.data.email && (
                  <EmailListItem email={props.data.data.email} />
                )}
                {props.data.data.phonenumber && (
                  <PhoneNumberListItem
                    phonenumber={props.data.data.phonenumber}
                  />
                )}
                {props.data.data.url && (
                  <WebsiteListItem url={props.data.data.url} />
                )}
              </ul>

              <div className="Accordion Accordion--personalizedDac u-hidden u-sm-block">
                <details className="Accordion-item">
                  <summary className="Accordion-toggle u-paddingLeft7gu u-textBold u-sm-marginBottom3gu">
                    Contact
                  </summary>
                  <div className="Accordion-content u-bgColorWhite u-padding0 u-borderBottom0 u-lg-">
                    <ul className="AdvisorPersonalized-contactInfo List Grid">
                      {props.data.data.email && (
                        <EmailListItem email={props.data.data.email} />
                      )}
                      {props.data.data.phonenumber && (
                        <PhoneNumberListItem
                          phonenumber={props.data.data.phonenumber}
                        />
                      )}
                      {props.data.data.url && (
                        <WebsiteListItem url={props.data.data.url} />
                      )}
                    </ul>
                  </div>
                </details>
              </div>
            </div>
          </div>
        </div>
        <div
          className="AdvisorPersonalized-disclosure AdvisorPersonalized-disclosure--nextGen2024 Content--reversed u-textSizeMsrm2 u-marginTop2gu"
          data-testid={testId ? `${testId}-disclosure` : null}
          dangerouslySetInnerHTML={{ __html: content.disclosure }}
        />
        <div className="AdvisorPersonalized-disclosure AdvisorPersonalized-disclosure--nextGen2024 Content--reversed u-textSizeMsrm2">
          <p>
            {'The initial consultation provides an overview of financial planning concepts. ' +
              'You will not receive written analysis and/or recommendations.'}
          </p>
        </div>
      </div>
    </div>
  )
}

const Personalized = props => {
  const { content, testId } = props

  if (content.template === 'nextgen2024') {
    return <PersonalizedNextGenTemplate data={props} testId={testId} />
  }

  return (
    <div id="personalizedDac" className={classes.container}>
      <div className={classes.padding}>
        <div className={classes.grid}>
          <div className={classes.gridLeft}>
            <Image data={props.data}></Image>
          </div>
          <div className={classes.gridRight}>
            <h2 className={classes.header}>
              {`Take the first step toward a confident financial future.
              Connect with ${props.data.name} for a complimentary consultation today.`}
            </h2>
            <ActionList className={classes.actionList} data={props.data} />
            <div id="disclosureSection" className={classes.bottomText}>
              <hr className={classes.hr} />
              <span className={classes.search}>
                <Icon description={'Search'} size={'u-textSizeMsrp1'}>
                  &#xe8b6;
                </Icon>
                {advisorSearchLink(content.template)}
              </span>
              <div
                className={classes.disclosure + ' u-marginTop2gu'}
                dangerouslySetInnerHTML={{ __html: content.disclosure }}
              />
              <div className={classes.disclosure}>
                <p>
                  {'The initial consultation provides an overview of financial planning concepts. ' +
                    'You will not receive written analysis and/or recommendations.'}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Personalized
