import { reactToCwp } from '../react-utils.jsx'
import { Alert } from '@ampcore/design-system'
import { HeroSectionFront } from '@ampcore/design-system'
import { HeroTopicFront } from '@ampcore/design-system'
import { Spotlight } from '@ampcore/design-system'
import { Cta } from '@ampcore/design-system'
import { empty } from '../dom'
import { fetchJSON } from '../fetch'
import { parseSettings, track } from '@cwp/utils'
import { toCamelCase } from '../string-utils'
import Advisor from '../../components/Advisor/index.js'
import AdvisorQuestion from '../../components/AdvisorQuestion/index.js'
import Quiz from '../../components/Quiz/index.js'
import * as Video from '../../components/Video/index.js'
import TabbedContent from '../../components/TabbedContent/index.js'
import Form from '../../components/Form/index.js'
import MarketWatch from '../../components/MarketWatch/index.js'
import Search from '../../components/Search/index.js'
import LogoutBannerStatic from '../../components/Personalization/index'
import {
  CampaignHeader,
  CampaignFooter
} from '../../components/CampaignPage/index'
import SortedTab from '../../components/SortedTab/index'
import LogoutMessage from '../../components/LogoutMessage'
import Caption from '../../components/Caption/index'
import CheckboxList from '../../components/Form/views/CheckboxList'
import Commitments from '../../components/Commitments/index.js'
import Categories from '../../components/Categories/index.js'
import CardCollection from '../../components/CardsRedesign/CardCollection/index.js'
import AnchorLinks from '../../components/AnchorLinks/index.js'
import ComplexHero from '../../components/ComplexHero/index.js'
import SkipLinks from '../../components/SkipLinks/index.js'
import Promo from '../../components/Promo/index.js'
import TwoColumnBanner from '../../components/TwoColumnBanner/index.js'

const components = {
  Advisor: Advisor,
  AdvisorQuestion,
  Quiz: Quiz,
  Video: Video,
  Form,
  TabbedContent,
  MarketWatch,
  Search,
  LogoutBannerStatic,
  CampaignHeader,
  CampaignFooter,
  SortedTab,
  LogoutMessage,
  Caption,
  CheckboxList,
  Commitments,
  Spotlight: reactToCwp(Spotlight),
  Cta: reactToCwp(Cta),
  Categories,
  HeroTopicFront: reactToCwp(HeroTopicFront),
  HeroSectionFront: reactToCwp(HeroSectionFront),
  CardCollection,
  Alert: reactToCwp(Alert),
  AnchorLinks,
  ComplexHero,
  SkipLinks,
  Promo,
  TwoColumnBanner
}

const COMPONENT_ATTR = 'data-is'
const SETTINGS_ATTR = 'data-settings'
const DOCUMENT_ATTR = 'data-document'
const DOCUMENT_REGEX = new RegExp(`^${DOCUMENT_ATTR}`)
const NAMED_DOCUMENT_REGEX = new RegExp(`^${DOCUMENT_ATTR}-`)

// Class added to an element when the component has been initialized
export const loadedClass = 'component-loaded'

/**
 * Queries the DOM for components to initialize
 *
 * @returns {Number} Number of components initialized
 */
export default function loadComponents() {
  const components = Array.from(
    document.querySelectorAll(`[${COMPONENT_ATTR}]:not(.${loadedClass})`)
  )

  components.forEach(component => {
    try {
      initComponent(component)
    } catch (e) {
      console.error('Error loading component', e)
    }
  })

  return components.length
}

/**
 * Initializes a component specified by the given DOM element
 *
 * @param {Element} DOM element to initialize
 * @returns {Promise} Resolves with the initialized component
 */
function initComponent(el) {
  el.classList.add(loadedClass)
  const { name, settings, documentRequests } = parseElement(el)

  return Promise.all([...documentRequests])
    .then(([...documents]) => {
      if (!(name in components)) {
        return
      }

      const Component = components[name]
      let content = parseElementAttributes(el)

      if (documents.length === 1) {
        const doc = documents[0]

        if (!doc.error) {
          content = Object.assign(content, doc.data)
        } else {
          track.error(doc.error)
        }
      } else {
        content = Object.assign(
          content,
          documents.reduce((acc, doc) => {
            if (!doc.error) {
              acc[doc.name] = doc.data
            } else {
              track.error(doc.error)
            }

            return acc
          }, {})
        )
      }

      return new Component(empty(el), {
        ...settings,
        content
      })
    })
    .catch(err => {
      console.error('Error loading component', el, 'because', err)
    })
}

/**
 * Parses the given element to identify the component representation
 *
 * @param {Element} DOM element to parse
 * @returns {Object} Representation of the component
 */
function parseElement(el) {
  const { value: name } = el.attributes[`${COMPONENT_ATTR}`]
  const { value: settings } = el.attributes[`${SETTINGS_ATTR}`] || {}

  return {
    name,
    settings: parseSettings(settings),
    documentRequests: fetchDocuments(el.attributes)
  }
}

/**
 * Parses the attributes on an element so they can be used for configuration
 * @param {Element} DOM element to parse the attributes of
 * @returns {Object} map of attributes from the element
 */
function parseElementAttributes(el) {
  let attributes = {}

  // Direct attributes, like <attribute>=<value>
  Array.from(el.attributes).forEach(attribute => {
    attributes[parseAttributeName(attribute.name)] = attribute.value
  })

  return Object.assign(
    attributes,
    parseChildAttributes(Array.from(el.children))
  )
}

/**
 * Child attributes, useful for HTML transposition.
 * Looks for: <some-attribute>Value</some-attribute> which would become someAttribute: Value
 * Or: <div data-attribute="someAttribute">Value</div> which will become someAttribute: Value
 * Or: <some-attribute data-object><some-child>Value</some-child></some-attribute> which will become:
 *    someAttribute: { someChild: Value }
 * @param {Array[Element]} Array of DOM elements to parse
 * @returns {Object} map of children and attributes
 */
function parseChildAttributes(children) {
  let attributes = {}

  children
    .filter(
      child =>
        child.hasAttribute('data-attribute') || child.tagName.includes('-')
    )
    .forEach(child => {
      const name =
        child.getAttribute('data-attribute') ||
        parseAttributeName(child.tagName)
      const hasAttributes = child.attributes.length > 0

      let val
      if (!hasChildAttributes(child)) {
        val = hasAttributes ? parseElementAttributes(child) : child.innerHTML
      } else {
        val = parseElementAttributes(child)
      }

      if (name in attributes && !Array.isArray(attributes[name])) {
        attributes[name] = [attributes[name]]
      }

      if (name in attributes) {
        attributes[name].push(val)
      } else {
        attributes[name] = val
      }
    })

  return attributes
}

/**
 *
 * @param {String} attribute name
 * @returns {String} camelCase attribute name with prefixes removed
 */
function parseAttributeName(name) {
  if (name.indexOf('-') !== -1) {
    return toCamelCase(name.toLowerCase().replace(/^data-/, ''))
  }
  return name
}

/**
 * Checks if a given element which represents an attribute has child attributes
 * @param {HTMLElement} el
 * @returns {Boolean} whether there are child attributes
 */
function hasChildAttributes(el) {
  return (
    el.hasChildNodes &&
    el.children &&
    el.children[0] &&
    el.children[0].tagName.indexOf('-') !== -1
  )
}

/**
 * Fetch document data for a given NameNodeMap
 *
 * @param {NameNodeMap} Element attributes
 * @returns {Array} Promises resolving with the document data
 */
function fetchDocuments(attrs) {
  const urls = Array.from(attrs).filter(attr => DOCUMENT_REGEX.test(attr.name))

  if (urls.length === 1) {
    return [
      fetchJSON(urls[0].value)
        .then(data => ({ data }))
        .catch(error => ({ error }))
    ]
  } else if (urls.length > 1) {
    return urls.map(({ name, value }) => {
      name = name.replace(NAMED_DOCUMENT_REGEX, '')

      return fetchJSON(value)
        .then(data => ({ data, name }))
        .catch(error => ({ error, name }))
    })
  }

  return []
}
