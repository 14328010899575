/*
 * Personalization data retrieval
 */

const CONSTs = {
  storageName: 'clientPageVisitInfo'
}

const getPersonalizedStorage = () => {
  if (window.localStorage) {
    const retrievedData = window.localStorage.getItem(CONSTs.storageName)
    return retrievedData ? JSON.parse(retrievedData) : false
  }
  return false
}

const getPersonalizedLastVisitedStorage = () => {
  const currentStorageItems = getPersonalizedStorage()
  if (currentStorageItems) {
    let filteredStorage = currentStorageItems.filter(item => {
      return item.hasVisited === true && item.timestamp > 0
    })
    if (filteredStorage && filteredStorage.length) {
      filteredStorage.sort((item, item2) => item2.timestamp - item.timestamp)
    }
    return filteredStorage
  }
  return false
}

const checkIfClientVisitedPersonalizedPages = () => {
  const clientVisitHistory = getPersonalizedLastVisitedStorage()
  if (clientVisitHistory && clientVisitHistory.length) {
    return true
  }
  return false
}

module.exports = {
  getClientData: getPersonalizedStorage,
  getClientLastVisitData: getPersonalizedLastVisitedStorage,
  checkIfClientVisitedCreditCardPage: checkIfClientVisitedPersonalizedPages
}
