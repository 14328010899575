import React from 'react'
import '../Advisor.less'
import { getAdvisorLinkWithCookie } from '../../../../ui/AdvisorQueryParameter/advisors-query-param'

function wrapLinkIfExists(element, url) {
  if (url) {
    const link = getAdvisorLinkWithCookie(url)
    element = (
      <a
        className="Link u-maxWidth100 u-linkClean"
        href={link}
        target="_blank"
        rel="noopener noreferrer"
      >
        {element}
      </a>
    )
  }

  return element
}

function AdvisorImage({ data }) {
  const src = data.image || `${window.AMPCOMPATH}images/dac-missing-image.png`
  const alt = data.name || 'Missing Image'
  const img = (
    <img src={src} alt={alt} className="Advisor-image u-maxWidth100" />
  )

  return wrapLinkIfExists(img, data.urls.site)
}

function AdvisorImageNoLink({ data }) {
  const src = data.image || `${window.AMPCOMPATH}images/dac-missing-image.png`
  const alt = data.name || 'Missing Image'
  const img = (
    <img src={src} alt={alt} className="Advisor-image u-maxWidth100" />
  )

  return img
}

function SocialIcon(icon, url, definedSize) {
  const icons = {
    twitter: '&#xea96;',
    facebook: '&#xea91;',
    linkedin: '&#xeac9;'
  }

  return (
    <li
      key={icon}
      className="u-textSizeMsrp1 u-flexJustifyCenter u-inlineBlock u-marginRight2gu"
    >
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        className="Icon-link"
      >
        <span
          aria-hidden="true"
          className={`Icon ${definedSize}`}
          dangerouslySetInnerHTML={{ __html: icons[icon] }}
        />
        <span className="u-hiddenVisually">{icon}</span>
      </a>
    </li>
  )
}

function SocialIcons({ data, definedSize }) {
  const icons = Object.keys(data.social).map(icon =>
    SocialIcon(icon, data.social[icon], definedSize)
  )

  if (icons.length === 0) return null
  return <ul className="u-decoBorderReset">{icons}</ul>
}

function ContentCallout({ data, content }) {
  const { clientContent, clientLabels } = content
  const referLink = data.type === 'team' ? data.urls.site : data.urls.referme
  const referLinkWithAuth = getAdvisorLinkWithCookie(referLink)
  const div = (
    <div className="Callout u-flexAlignSelfStart u-marginBottom8gu u-size3of5 u-sm-sizeFull u-padding6gu u-decoRoundedCorners u-sm-marginBottom4gu u-marginTopM6gu u-sm-marginTop0">
      <h3
        className="AdvisorClient-heading u-textSecondary u-marginBottom2gu"
        dangerouslySetInnerHTML={{ __html: clientLabels.heading }}
      ></h3>
      <div
        className="AdvisorClient-content u-marginBottom4gu u-textLoose"
        dangerouslySetInnerHTML={{ __html: clientContent }}
      />
      <a
        className="AdvisorClient-refer"
        href={referLinkWithAuth}
        target="_blank"
        rel="noopener noreferrer"
      >
        {clientLabels.refer}
      </a>
    </div>
  )

  return referLink ? div : ''
}

function ReferralLink({ data, content }) {
  const { clientLabels } = content
  const referLink = data.type === 'team' ? data.urls.site : data.urls.referme
  const referLinkWithAuth = getAdvisorLinkWithCookie(referLink)
  const link = (
    <a
      className="AdvisorClient-refer Button Button--secondary Button--secondary--action-blue u-marginTop4gu u-md-sizeFull u-sm-sizeFull"
      href={referLinkWithAuth}
      target="_blank"
      rel="noopener noreferrer"
    >
      {clientLabels.refer}
    </a>
  )

  return referLink && clientLabels.refer ? link : ''
}

function AdvisorName(data, classNames) {
  const header = <h3 className={classNames}>{data.name}</h3>

  return wrapLinkIfExists(header, data.urls.site)
}

function AdvisorNameNoLink(data, classNames) {
  const header = <h3 className={classNames}>{data.name}</h3>

  return header
}

export default function Client({ data, content, testId }) {
  const { clientLabels } = content
  const designations = data.designations && (
    <p className="u-marginBottom1gu">{data.designations}</p>
  )
  const title = data.title && (
    <p
      className="u-marginBottom2gu"
      dangerouslySetInnerHTML={{ __html: data.title }}
    ></p>
  )
  const email = data.email && (
    <p className="u-marginBottom2gu u-textBreak">{data.email}</p>
  )
  const phone = data.phone && <p className="u-marginBottom2gu">{data.phone}</p>

  return (
    <>
      {content.template === 'nextgen2024' ? (
        <div
          className="Advisor--color Advisor--clientView u-bgSizeCover u-bgColorMidnightBlue"
          style={{
            backgroundImage: `url(${window.AMPCOMPATH}images/facets/hero-facets-dark-midnight-blue.png)`
          }}
          data-testid={testId ? `${testId}-container` : null}
        >
          <div className="u-sizeConstrained u-paddingVert6gu">
            <div className="Card">
              <div className="AdvisorClient AdvisorClient--nextGen2024 Grid Card-body u-padding4gu">
                <div className="u-size8of12 u-flex u-flexRow u-paddingRight4gu u-md-sizeFull u-sm-sizeFull u-sm-flexCol u-md-paddingRight0 u-sm-paddingRight0">
                  <div className="AdvisorClient-image u-marginRight4gu u-sm-marginRight0 u-sm-marginBottom4gu">
                    <AdvisorImageNoLink data={data} />
                  </div>
                  <div className="AdvisorClient-info">
                    <h5 className="AdvisorClient-advisor Heading--withSeparator u-textUppercase u-textColorMidnightBlue">
                      {clientLabels.advisor}
                    </h5>
                    {AdvisorNameNoLink(
                      data,
                      'AdvisorClient-name u-textSecondary u-marginBottom1gu u-inlineBlock'
                    )}
                    {data.designations && (
                      <p className="u-md-marginVert2gu u-sm-marginVert2gu">
                        {data.designations}
                      </p>
                    )}
                    {data.title && (
                      <p dangerouslySetInnerHTML={{ __html: data.title }}></p>
                    )}
                    <div className="u-md-hidden u-sm-hidden">
                      <ReferralLink data={data} content={content} />
                    </div>
                  </div>
                </div>
                <div className="u-lg-hidden u-size12of12">
                  <ReferralLink data={data} content={content} />
                </div>
                <div className="u-size4of12 u-paddingLeft4gu u-borderLeft u-md-sizeFull u-md-paddingLeft0 u-md-paddingTop4gu u-md-borderLeft0 u-md-marginTop4gu u-md-borderTop u-sm-sizeFull u-sm-paddingLeft0 u-sm-paddingTop4gu u-sm-marginTop4gu u-sm-borderLeft0 u-sm-borderTop">
                  <div className="u-textPrimary u-textColorNeutralGrey u-textBold u-marginBottom2gu">
                    Contact
                  </div>
                  <ul className="AdvisorClient-contactInfo List Grid">
                    {email && (
                      <li className="List-item u-sizeFull u-flex u-flexAlignItemsCenter u-marginBottom1gu u-md-size6of12 u-md-marginBotton2gu u-sm-marginBottom2gu">
                        <span
                          className="adel-material-symbols u-textColorActionBlue u-marginRight2gu"
                          role="img"
                          title="happy"
                          aria-label="happy"
                        >
                          mail
                        </span>
                        <a
                          className="u-textTruncate"
                          href={`mailto:${data.email}`}
                        >
                          {data.email}
                        </a>
                      </li>
                    )}
                    {phone && (
                      <li className="List-item u-sizeFull u-flex u-flexAlignItemsCenter u-marginBottom1gu u-md-size6of12 u-md-marginBotton2gu u-sm-marginBottom2gu">
                        <span
                          className="adel-material-symbols u-textColorActionBlue u-marginRight2gu"
                          role="img"
                          title="phone"
                          aria-label="phone"
                        >
                          phone
                        </span>
                        <a href={`tel:${data.phone}`}>{data.phone}</a>
                      </li>
                    )}
                    {data.urls.site && (
                      <li className="List-item u-sizeFull u-flex u-flexAlignItemsCenter u-marginBottom1gu u-md-size6of12 u-md-marginBotton2gu u-sm-marginBottom2gu">
                        <span
                          className="adel-material-symbols u-textColorActionBlue u-marginRight2gu"
                          role="img"
                          title="new_window"
                          aria-label="new_window"
                        >
                          new_window
                        </span>
                        <a
                          href={data.urls.site}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Advisor website
                        </a>
                      </li>
                    )}
                    <li className="List-item u-sizeFull u-md-size6of12">
                      <SocialIcons data={data} definedSize="u-textSizeMsrp2" />
                    </li>
                  </ul>
                </div>
              </div>
              {/* <div>content<pre>{JSON.stringify(content, null, 2) }</pre></div>
                <div>data<pre>{JSON.stringify(data, null, 2) }</pre></div> */}
            </div>
            <div
              className="AdvisorClient-disclosure AdvisorClient-disclosure--nextGen2024 Content--reversed u-textSizeMsrm2 u-marginTop2gu"
              dangerouslySetInnerHTML={{ __html: content.disclosure }}
            />
          </div>
        </div>
      ) : (
        <div className="Advisor--color">
          <div className="u-sizeConstrained u-paddingVert10gu">
            <div className="AdvisorClient Grid Content--reversed u-sm-paddingVert4gu u-sm-paddingHoriz4gu u-padding8gu u-paddingBottom0">
              <div className="u-size1of4 u-sm-sizeFull u-md-size1of3 u-flex u-flexCol u-sm-flexAlignItemsStart u-flexAlignItemsCenter u-sm-paddingBottom8gu">
                <AdvisorImage data={data} />
              </div>
              <div className="Grid u-size3of4 u-sm-sizeFull u-md-size2of3 u-flex u-paddingLeft4gu u-sm-paddingLeft0">
                <h2 className="AdvisorClient-advisor Heading--withSeparator u-textSizeMsr u-textUppercase u-marginBottom5gu u-lg-textKernLoosest u-textBold">
                  {clientLabels.advisor}
                </h2>
                <div className="AdvisorClient-info u-paddingBottom8gu u-paddingRight8gu u-sm-paddingRight0 u-size2of5 u-sm-sizeFull">
                  {AdvisorName(
                    data,
                    'AdvisorClient-name u-textSecondary u-marginBottom2gu'
                  )}
                  {designations}
                  {title}
                  {email && <a href={`mailto:${data.email}`}>{email}</a>}
                  {phone && <a href={`tel:${data.phone}`}>{phone}</a>}
                  <SocialIcons data={data} definedSize="u-textSizeMsrp3" />
                </div>
                <ContentCallout data={data} content={content} />
              </div>
              <div className="u-sizeFull u-flex">
                <div className="u-md-size4of5 u-lg-size2of3 spacer"></div>
                <div className="u-md-size3of6 u-lg-size3of6 dac-disclosure">
                  <p
                    className="AdvisorClient-disclosure u-paddingBottom3gu u-sm-paddingBottom8gu u-marginTop2gu u-sm-marginTop0 u-sm-marginRight0 u-md-marginRight0 u-marginRightM4gu u-flexExpandLeft"
                    dangerouslySetInnerHTML={{ __html: content.disclosure }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
