import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import InputField from '../views/Input'
import SuccessMessage from '../views/SuccessMessage'
import {
  ErrorMessageComponent,
  PleaseNoteComponent,
  SendEmailButtonComponent
} from '../views/StatelessComponent'
import {
  isValidEmail,
  formatPhoneNumber,
  isValidPhoneNumber
} from '../../../common/utils'
import { formSelectedData, CONSTs } from '../views/utils'
import '../views/index.css'
import { Spacer } from '@ampcore/design-system'

const ContactFormAPRI = ({ callback, name, content }) => {
  const [formData, setFormData] = useState({
    firstname: null,
    lastname: null,
    emailaddress: null,
    phone: null
  })

  const [errorFields, setErrorFields] = useState({
    firstname: false,
    lastname: false,
    emailaddress: false,
    phone: false,
    emailWrongFormat: false
  })

  const phoneRef = useRef()
  const [loading, setLoading] = useState(false)
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [showServiceFailedMessage, setShowServiceFailedMessage] =
    useState(false)

  const validateField = (fieldName, value) => {
    let fieldErrors = { ...errorFields }
    let updatedFormData = { ...formData }
    const isNotEmpty = value?.trim().length > 0
    switch (fieldName) {
    case 'firstname':
      fieldErrors.firstname = !isNotEmpty
      updatedFormData.firstname = value.trim() || null
      break
    case 'lastname':
      fieldErrors.lastname = !isNotEmpty
      updatedFormData.lastname = value.trim() || null
      break
    case 'emailaddress':
      const isEmailValid = isValidEmail(value)
      fieldErrors.emailaddress = !isEmailValid
      fieldErrors.emailWrongFormat = !isEmailValid && isNotEmpty
      updatedFormData.emailaddress = fieldErrors.emailaddress ? null : value
      break
    case 'phone':
      const isValidPhone = isValidPhoneNumber(value, true)
      fieldErrors.phone = !isValidPhone
      if (isValidPhone) {
        const formattedPhone = formatPhoneNumber(value)
        phoneRef.current.value = formattedPhone
        updatedFormData.phone = formattedPhone
      } else {
        updatedFormData.phone = null
      }
      break
    default:
      updatedFormData[fieldName] = value
    }
    setFormData(updatedFormData)
    setErrorFields(fieldErrors)
  }

  const handleFieldChange = event => {
    const { name, value } = event.target
    validateField(name, value)
  }

  const isFormComplete = Object.entries(formData).every(([key, value]) => {
    return key === 'phone' || value !== null
  })

  const submitForm = event => {
    event.preventDefault()
    if (!isFormComplete) {
      let fieldErrors = { ...errorFields }
      fieldErrors.firstname = !formData.firstname
      fieldErrors.lastname = !formData.lastname
      fieldErrors.emailaddress = !isValidEmail(formData.emailaddress)
      fieldErrors.phone = !isValidPhoneNumber(formData.phone, true)
      setErrorFields(fieldErrors)
      return
    }
    setLoading(true)
    const items = formSelectedData(formData, content.formTemplate)
    if (callback && typeof callback === 'function' && items) {
      const showEmailIntroMessage = true
      const { emailGreeting, emailIntro, emailFormHeading } =
        content.formEmailInfo
      const updatedEmailInfo = {
        ...content.formEmailInfo,
        emailIntro: `${emailGreeting},<br>${emailIntro}<br><br><b>${emailFormHeading}</b><br>`
      }
      callback(
        items,
        updatedEmailInfo,
        showServerResponse,
        showEmailIntroMessage
      )
    }
  }

  const showServerResponse = (props, message) => {
    if (props && message) {
      if (message === CONSTs.SUCCESS) {
        setShowSuccessMessage(true)
        setShowServiceFailedMessage(false)
      } else {
        setShowSuccessMessage(false)
        setShowServiceFailedMessage(true)
      }
      setLoading(false)
    }
  }

  const renderSubmitButton = () => {
    const { formLabels } = content

    return (
      <SendEmailButtonComponent
        callback={submitForm}
        isLoading={loading}
        defaultLabel={formLabels.buttonlabel}
        loadingLabel={formLabels.buttonLoadingLabel}
      />
    )
  }

  const setEmailErrorMessage = (wrongEmail, message) => {
    return wrongEmail
      ? message.errorEmailaddressWrongFormat
      : message.errorEmailaddress
  }

  return (
    <Spacer spacing={content.componentSpacing}>
      {showSuccessMessage &&
        content.serviceMessages &&
        content.serviceMessages.serviceSuccess && (
        <SuccessMessage
          thankYou={content.serviceMessages.serviceSuccessThankyou}
          message={content.serviceMessages.serviceSuccess}
        />
      )}
      {!showSuccessMessage && (
        <div className="u-sizeConstrained">
          <div className="u-lg-size12of12 u-sm-sizeFull u-paddingBottom2gu">
            <div className="u-bgColorLight ContactFormAPRI-radius25">
              <div className="u-paddingVert12gu u-lg-before1of12 u-lg-after1of12 u-sm-paddingHoriz8gu u-md-paddingHoriz10gu">
                <h3 className="u-textDefault u-textCenter u-textSecondary u-textBold u-paddingBottom5gu">
                  {content.formContent.title}
                </h3>
                <h4 className="u-textDefault u-textCenter u-textColorBrand u-textSecondary u-textNormal u-lg-textSizeMsrp2 u-md-textSizeMsrp2 u-sm-textSizeMsrp1 u-paddingBottom2gu">
                  {content.formContent.introBody}
                </h4>
                <hr />
                <form
                  name={name}
                  onBlur={handleFieldChange}
                  onKeyUp={handleFieldChange}
                >
                  <div>
                    <div className="u-flex u-flexRow u-sm-flexCol">
                      <div className="u-size6of12 u-sm-sizeFull u-paddingTop5gu">
                        <InputField
                          name="firstname"
                          id="firstname"
                          label={content.formLabels.firstname}
                          error={errorFields.firstname}
                          message={content.formErrorMessages.errorFirstname}
                          hideStyle="u-hidden"
                          maxLength={100}
                        />
                      </div>
                      <div className="u-size6of12 u-lg-before1of12 u-md-before1of12 u-sm-sizeFull u-paddingTop5gu">
                        <InputField
                          name="lastname"
                          id="lastname"
                          label={content.formLabels.lastname}
                          error={errorFields.lastname}
                          message={content.formErrorMessages.errorLastname}
                          hideStyle="u-hidden"
                          maxLength={100}
                        />
                      </div>
                    </div>
                    <div className="u-flex u-flexRow u-sm-flexCol">
                      <div className="u-size6of12 u-sm-sizeFull u-paddingTop5gu">
                        <InputField
                          name="emailaddress"
                          id="email"
                          label={content.formLabels.emailaddress}
                          error={errorFields.emailaddress}
                          message={setEmailErrorMessage(
                            errorFields.emailWrongFormat,
                            content.formErrorMessages
                          )}
                          hideStyle="u-hidden"
                        />
                      </div>
                      <div className="u-size6of12 u-lg-before1of12 u-md-before1of12 u-sm-sizeFull u-paddingTop5gu">
                        <InputField
                          name="phone"
                          id="phone"
                          label={content.formLabels.phone}
                          value={formData.phone}
                          reference={phoneRef}
                          error={errorFields.phone}
                          message={content.formErrorMessages.errorPhone}
                          hideStyle="u-hidden"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="Disclaimer-text u-paddingVert7gu">
                    {content.formContent.disclosure}
                  </div>
                  {showServiceFailedMessage &&
                    content.serviceMessages.serviceFailed && (
                    <ErrorMessageComponent
                      message={content.serviceMessages.serviceFailed}
                    />
                  )}
                  <div className="u-sizeFull u-flex u-flexJustifyEnd">
                    {renderSubmitButton()}
                  </div>
                </form>
              </div>
            </div>
            {content.formPleaseNote && content.formPleaseNote.message && (
              <PleaseNoteComponent content={content.formPleaseNote.message} />
            )}
          </div>
        </div>
      )}
    </Spacer>
  )
}

ContactFormAPRI.propTypes = {
  callback: PropTypes.func,
  name: PropTypes.string,
  content: PropTypes.shape({
    formLabels: PropTypes.shape({
      firstname: PropTypes.string.isRequired,
      lastname: PropTypes.string.isRequired,
      emailaddress: PropTypes.string.isRequired,
      phone: PropTypes.string.isRequired,
      buttonlabel: PropTypes.string.isRequired,
      buttonLoadingLabel: PropTypes.string.isRequired
    }).isRequired,
    formContent: PropTypes.shape({
      title: PropTypes.string.isRequired,
      introBody: PropTypes.string.isRequired,
      disclosure: PropTypes.string.isRequired
    }).isRequired,
    formEmailInfo: PropTypes.shape({
      emailApi: PropTypes.string.isRequired,
      emailFormHeading: PropTypes.string.isRequired,
      emailGreeting: PropTypes.string.isRequired,
      emailIntro: PropTypes.string.isRequired,
      subject: PropTypes.string.isRequired,
      toEmailAddress: PropTypes.string.isRequired
    }).isRequired,
    formErrorMessages: PropTypes.shape({
      errorFirstname: PropTypes.string.isRequired,
      errorLastname: PropTypes.string.isRequired,
      errorEmailaddress: PropTypes.string.isRequired,
      errorEmailaddressWrongFormat: PropTypes.string.isRequired,
      errorPhone: PropTypes.string.isRequired
    }).isRequired,
    serviceMessages: PropTypes.shape({
      serviceSuccessThankyou: PropTypes.string.isRequired,
      serviceSuccess: PropTypes.string.isRequired,
      serviceFailed: PropTypes.string.isRequired
    }),
    formPleaseNote: PropTypes.string,
    formTemplate: PropTypes.string
  }).isRequired
}

export default ContactFormAPRI
