import React, { useState } from 'react'
import { ctaFilterXss } from './cta-filter-xss'
import xss from 'xss'
import Spacer from '../Spacer/Spacer'
import './style.module.scss'
import { CtaBgColor } from './CtaBgColor.type'
import { SpacerSpacing } from '../Spacer/SpacerSpacing.type'

export type CtaProps = {
  content: {
    componentSpacing?: SpacerSpacing
    body?: string
    bgcolor?: CtaBgColor
    headline?: string
    heading?: string
    linkHref?: string
    linkTarget?: string
    analytics?: string
  }
  testId?: string
}

export const Cta = (props: CtaProps) => {
  const { content, testId } = props
  const [isHover, setIsHover] = useState(false)

  const {
    componentSpacing,
    body,
    bgcolor,
    headline,
    heading,
    linkHref,
    linkTarget,
    analytics
  } = content

  const handleDarken = () => {
    setIsHover(true)
  }

  const handleLighten = () => {
    setIsHover(false)
  }

  let bgColorClass = ''
  switch (bgcolor) {
    case 'purple':
      bgColorClass = 'u-bgColorPurpleLight'
      break
    case 'lime':
      bgColorClass = 'u-bgColorLimeLight'
      break
    case 'mint':
      bgColorClass = 'u-bgColorMintLight'
      break
    default:
      bgColorClass = 'u-bgColorStone'
  }

  return (
    <Spacer spacing={componentSpacing}>
      <div className="u-sizeConstrained">
        <div
          className={`Cta u-posRelative ${bgColorClass}`}
          data-testid={testId ? `${testId}` : null}
        >
          <div className={`Cta-background ${isHover ? 'active' : ''}`}></div>
          <a
            className="Link--stretched"
            href={linkHref}
            target={linkTarget}
            aria-label={heading}
            onMouseEnter={handleDarken}
            onMouseLeave={handleLighten}
            onFocus={handleDarken}
            onBlur={handleLighten}
            data-analytics={analytics}
            data-testid={testId ? `${testId}-link` : null}
          >
            <span className="u-hidden">{heading}</span>
          </a>
          <div className="u-lg-flex u-md-flex u-paddingHoriz8gu u-paddingVert4gu">
            <div className="Cta-body u-lg-size3of4 u-md-size3of4">
              {headline && (
                <h5
                  className="Cta-superHeadline u-textColorNeutralGrey u-textUppercase u-marginBottom4gu"
                  data-testid={testId ? `${testId}-super-headline` : null}
                  dangerouslySetInnerHTML={{ __html: xss(headline) }}
                ></h5>
              )}
              {heading && (
                <h3
                  className="Cta-heading u-textColorNeutralGrey u-marginBottom2gu"
                  data-testid={testId ? `${testId}-heading` : null}
                  dangerouslySetInnerHTML={{ __html: xss(heading) }}
                ></h3>
              )}
              {body && (
                <div
                  className="Cta-content Content u-textColorNeutralGrey"
                  dangerouslySetInnerHTML={{
                    __html: ctaFilterXss.process(body)
                  }}
                  data-testid={testId ? `${testId}-body` : null}
                ></div>
              )}
            </div>
            <div className="Cta-link u-size1of4 u-flex u-lg-flexJustifyEnd u-md-flexJustifyEnd u-sm-paddingTop3gu u-flexAlignItemsCenter">
              <span
                className={`Icon u-flex u-flexAlignItemsCenter u-flexJustifyCenter u-textColorNeutralGrey ${
                  isHover ? 'active' : ''
                }`}
              >
                &#x25b9;
              </span>
            </div>
          </div>
        </div>
      </div>
    </Spacer>
  )
}

export default Cta
