import React from 'react'
import cookies from 'js-cookie'
import { getFaceProfile } from '../../services/advisors'
import { BrandIcon } from '@ampcore/design-system'
import { getAdvisorLinkWithCookie } from '../../ui/AdvisorQueryParameter/advisors-query-param'
import './advisor-question.css'

const FACE_PROFILE_COOKIE = 'FaceProfileCookie'

export default class AdvisorQuestion extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      locPermission: true
    }
  }

  componentDidMount() {
    this.init().catch(console.error)
  }

  async init() {
    if (await this.initClient()) return
    this.setState({
      advisorData: { name: 'no advisor' },
      loading: false,
      View: 'prospect'
    })
  }

  async initClient() {
    const faceProfile = cookies.get(FACE_PROFILE_COOKIE)
    let success = false

    if (faceProfile) {
      const advisorRes = await getFaceProfile({ id: faceProfile })
      success = true // even if we error out we use this
      this.setState({
        advisorData: advisorRes.data,
        loading: false,
        View: 'client'
      })
    }
    return success
  }

  render() {
    const { content, testId } = this.props
    const bgColorSectionTestId = testId ? `${testId}-bg-color-section` : null

    const classNames = ['Advisor--color', 'u-padding5gu', 'u-sm-marginHoriz5gu']
    switch (content.bgColor) {
    case 'Purple':
      classNames.push('u-bgColorPurpleLight')
      break
    case 'Mint':
      classNames.push('u-bgColorMintLight')
      break
    case 'Lime':
      classNames.push('u-bgColorLimeLight')
      break
    default:
      classNames.push('u-bgColorStone')
    }
    return (
      <section
        className="AdvisorQuestion u-sm-sizeConstrainedFlush u-sizeConstrained component-wrapper u-paddingVert10gu"
        data-analytics-container="AdvisorQuestion"
      >
        <div className={content.sizingClasses}>
          <div
            className={classNames.join(' ')}
            data-testid={bgColorSectionTestId}
          >
            <div className="AdvisorContent">
              <div className="u-flex u-flexAlignItemsStart u-sm-flexCol">
                <BrandIcon
                  icon={content.icon}
                  className="BrandIcon--sizeMedium u-marginRight5gu"
                  style={{ fill: '#0D6573' }}
                  testId={testId ? `${testId}-icon` : null}
                />
                <h3 className="u-marginTop1gu u-textDefault">
                  {content.headline}
                </h3>
              </div>
              {this.renderQuestion(content.questions)}
              <div className="card u-marginVert5gu"></div>
              <div className="u-flex u-flexAlignItemsStart u-md-flexCol u-sm-flexCol">
                {this.bodyHTML()}
                <div className="u-marginLeft15gu u-paddingLeft12gu u-md-marginLeft0 u-sm-marginLeft0 u-md-paddingLeft0 u-sm-paddingLeft0">
                  {this.renderEmailLink()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }

  bodyHTML() {
    const { testId } = this.props
    const loadingTestId = testId ? `${testId}-loading` : null

    if (this.state.loading) {
      return (
        <div
          className="u-paddingBottom5gu u-posRelative"
          data-testid={loadingTestId}
        >
          <div className="Loading u-posAbsoluteCenter u-textColorBrand">
            <div className="Loading-indicator"></div>
            <div className="Loading-indicator"></div>
            <div className="Loading-indicator"></div>
          </div>
        </div>
      )
    }
    const advisor = this.state.advisorData

    if (this.state.View === 'client' || this.missingProfile()) {
      if (!advisor || !advisor.name || this.missingProfile()) {
        const bodyText = this.props.content.loadErrorText.replaceAll(
          '%page%',
          window.location.href
        )
        return (
          <div
            className="u-md-paddingBottom5gu u-sm-paddingBottom5gu  u-flexGrow1"
            dangerouslySetInnerHTML={{ __html: bodyText }}
          ></div>
        )
      }
    }
    const nameReplace =
      advisor.urls && advisor.urls.site
        ? '<a data-analytics="advisorQuestion-advisor-link" href="' +
          getAdvisorLinkWithCookie(advisor.urls.site) +
          '">' +
          advisor.name +
          '</a>'
        : advisor.name
    const bodyText = this.props.content.resultsBodyClient.replaceAll(
      '%advisor%',
      nameReplace
    )
    return (
      <div
        className="u-md-paddingBottom5gu u-sm-paddingBottom5gu  u-flexGrow1"
        dangerouslySetInnerHTML={{ __html: bodyText }}
      ></div>
    )
  }

  renderQuestion(questions) {
    return (
      <ul className="Content u-paddingTop5gu u-paddingLeft5gu u-margin0">
        {questions.map((question, index) => (
          <li className="u-paddingLeft2gu u-marginBottom3gu" key={index}>
            {question}
          </li>
        ))}
      </ul>
    )
  }

  renderEmailLink() {
    if (this.missingProfile()) {
      return
    }
    const content = this.props.content
    if (this.state.View === 'prospect') {
      const customAuth = getAdvisorLinkWithCookie(content.buttonHrefProspect)
      return this.renderButton(customAuth, content.buttonText)
    }
    const advisorData = this.state.advisorData
    if (!advisorData) return

    const phoneHref = 'tel:' + advisorData.phone
    const emailHref =
      'mailto:' +
      advisorData.email +
      '?subject=' +
      encodeURIComponent(content.emailSubject) +
      '&body=' +
      encodeURIComponent(content.emailBody)
    return !advisorData.email
      ? this.renderButton(phoneHref, content.buttonTextPhone)
      : this.renderButton(emailHref, content.buttonText)
  }

  renderCallLink() {
    return this.renderButton(
      this.state.advisorData.phone,
      this.props.content.callbuttonterxt
    )
  }

  renderButton(buttonHref, buttonText, newWindow) {
    const btnAnalytics = 'advisorQuestion-button ' + buttonText
    return (
      <a
        data-analytics={btnAnalytics}
        className="Button Button--primary u-textNoWrap"
        href={buttonHref}
        target={newWindow && '_blank'}
        rel={newWindow && 'noreferrer'}
      >
        {buttonText}
      </a>
    )
  }

  missingProfile() {
    const faceProfile = cookies.get(FACE_PROFILE_COOKIE)
    return (
      !faceProfile && this.props.content.resultsBodyClient.includes('%advisor%')
    )
  }
}
