import cn from 'classnames'
import React from 'react'
import { isNumber } from '@cwp/utils'
import './Quiz.less'

function CTA({ content, selectedAnswer }) {
  let result

  if (isNumber(selectedAnswer)) {
    const answer = content.answers.answer[selectedAnswer]
    const button = buildButton(answer)

    result = (
      <div className="Content quiz-right">
        <div className="big-number">
          <h1 className="Type-Display Content-percentage u-textColorDuff u-textSolid u-marginBottom3gu">
            {answer.percentage}
          </h1>
        </div>
        <p
          className="u-marginBottom5gu answer-body is-animated"
          dangerouslySetInnerHTML={{ __html: answer.content }}
        ></p>
        {button}
      </div>
    )
  } else {
    const button = buildButton(content)

    result = (
      <div className="Content quiz-right">
        <div className="big-number">
          <h1 className="Type-Display Content-percentage u-textColorDuff u-textSolid u-marginBottom3gu">
            {content.percentage}
          </h1>
        </div>
        <p
          className="u-marginBottom5gu answer-body is-animated"
          dangerouslySetInnerHTML={{ __html: content.content }}
        ></p>
        {button}
      </div>
    )
  }

  return result
}

function buildButton(link) {
  if (!link.linkUrl) {
    return ''
  }
  return (
    <a
      href={link.linkUrl}
      className="Button Button--primary quiz-anim quiz-anim-Active"
      target={link.linkTarget}
      rel="noopener noreferrer"
    >
      {link.linkIcon}
      {link.linkText}
    </a>
  )
}

export default class Quiz extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedAnswer: null
    }
  }

  clickAnswer(event, index) {
    event.preventDefault()
    this.setState({ selectedAnswer: index })
    var quizAnswer = document.querySelectorAll('.big-number')
    var quizBody = document.querySelectorAll('.answer-body')
    for (let e of quizAnswer) {
      e.classList.remove('u-animFadeInUp')
      void e.offsetWidth
      e.classList.add('u-animFadeInUp')
    }
    for (let e of quizBody) {
      e.classList.remove('is-animated')
      void e.offsetWidth
      e.classList.add('is-animated')
    }
  }

  render() {
    const { content } = this.props

    return (
      <section className="u-sizeConstrained">
        <div className="Card quiz-card u-flex u-flexRow u-sm-flexCol u-padding0 u-decoBorderLightFull">
          <div
            className="Content quiz-card-question u-size6of12 u-sm-sizeFull u-bgColorWhite"
            data-analytics-container="Quiz Callout"
          >
            <h3
              className="quiz-super-header Heading--withSeparator u-marginBottom3gu u-textUppercase"
              dangerouslySetInnerHTML={{ __html: content.superheadline }}
            ></h3>
            <h4
              className="quiz-content-header u-textColorBrand u-marginBottom3gu"
              dangerouslySetInnerHTML={{ __html: content.headline }}
            ></h4>
            <h4
              className="quiz-content-header u-marginBottom4gu u-marginTop0"
              dangerouslySetInnerHTML={{ __html: content.question }}
            ></h4>
            <div className="Button-group u-flex">
              {Array.isArray(content.answers.answer) &&
                content.answers.answer.map((answer, i) => (
                  <button
                    key={i}
                    className={cn('Button', 'Button--circular')}
                    onClick={event => this.clickAnswer(event, i)}
                    data-analytics="Stance Button"
                  >
                    {answer.label}
                  </button>
                ))}
            </div>
          </div>
          <div
            className="quiz-card-answer u-flex u-flexAlignItemsCenter u-size6of12 u-sm-sizeFull"
            data-analytics-container="Quiz Answer"
          >
            <CTA content={content} selectedAnswer={this.state.selectedAnswer} />
          </div>
        </div>
      </section>
    )
  }
}
