import { FilterXSS } from 'xss'

// escape rich text editor markup dangerous html
// prevent anchor links, etc.
export const ctaFilterXss = new FilterXSS({
  whiteList: {
    b: [],
    br: [],
    em: [],
    hr: [],
    i: [],
    li: [],
    ol: [],
    p: ['class'],
    div: ['class'],
    span: [],
    sub: [],
    sup: [],
    strong: [],
    u: [],
    ul: [],
    h2: [],
    h3: [],
    h4: [],
    h5: [],
    h6: [],
    a: ['href', 'title', 'target'] // TODO remove CTA should not support anchor
  }
})
