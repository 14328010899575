import { emailForm } from './downloadResults.js'
import data from './amp-ira-content-json.json'

// modals

const attachListeners = (toolTip, button) => {
  let toggle = false
  button.addEventListener('click', e => {
    e.preventDefault()
    toggle = !toggle
    toolTip.style.visibility = toggle ? 'visible' : 'hidden'
    arrowPosition(toolTip, button)
  })
  const doc = document.querySelector('.rollover-component')
  doc.addEventListener('click', e => {
    if (e.target !== button) {
      toggle = false
      toolTip.style.visibility = toggle ? 'visible' : 'hidden'
    }
  })
}

function tooltipListener() {
  const activeSlide = findActive()
  const $questionName =
    activeSlide.querySelector('.rollover-question').dataset.name
  const $tipSelector = `.Tooltip[for="${$questionName}"]`
  const toolTip = document.querySelector($tipSelector)
  const $selectorVal = `[data-name='${$questionName}'] a`
  const toolTipBtn = activeSlide.querySelector($selectorVal)
  if (toolTipBtn) {
    toolTipBtn.classList.add('Icon--after')
    toolTipBtn.dataset.icon = '\ue900'
    attachListeners(toolTip, toolTipBtn)
  }
}

function dfnToolTIp(container, data) {
  const toolTipDFNAll = container.querySelectorAll('dfn.c-dfn-withTooltip')
  toolTipDFNAll.forEach(toolTipDfn => {
    const newToolTip = document.createElement('div')
    const toolTipContent = document.createElement('div')
    const newToolTipClassList = ['Tooltip', 'Tooltip-dfn', 'u-marginTop2gu']
    newToolTip.classList.add(...newToolTipClassList)
    toolTipContent.classList.add('Tooltip-content')
    newToolTip.style.width = toolTipDfn.parentNode.offsetWidth + 'px'
    const dfnToolTipsOpts = data.tooltips.yourOptionMessages
    const dfnToolTipsQs = data.tooltips.questionMessages
    const dataFor = toolTipDfn.dataset.for
    const toolTipOptions = dfnToolTipsOpts.find(tips => tips.id === dataFor)
    const toolTipQuestions = dfnToolTipsQs.find(tips => tips.id === dataFor)
    toolTipOptions
      ? (toolTipContent.innerHTML = toolTipOptions.message)
      : (toolTipContent.innerHTML = toolTipQuestions.message)
    newToolTip.appendChild(toolTipContent)
    toolTipDfn.appendChild(newToolTip)
    toolTipDfn.setAttribute('tabindex', '-1')
    attachListeners(newToolTip, toolTipDfn)
  })
}

function aboutRolloverTooltip() {
  const aboutBtn = document.querySelector('.about-rollover button')
  if (aboutBtn) {
    const aboutModal = document.querySelector('.Modal.rollover-about')
    const aboutClose = aboutModal.querySelector('button')
    aboutBtn.addEventListener('click', () => {
      aboutModal.style.display = 'block'
    })
    aboutClose.addEventListener('click', () => {
      aboutModal.style.display = 'none'
    })
  }
}

function arrowPosition(currentTooltip, button) {
  const buttonPos = button.offsetLeft
  const tipPos = currentTooltip.offsetLeft
  var styleElem = document.head.appendChild(document.createElement('style'))
  if (button.classList.contains('c-dfn-withTooltip')) {
    const leftPos = buttonPos - tipPos + 'px'
    styleElem.innerHTML = `#${button.id} .Tooltip:before, #${button.id} .Tooltip:after  {left: ${leftPos};}`
  } else {
    const leftPos = buttonPos + 'px'
    styleElem.innerHTML = `.Tooltip:before, .Tooltip:after  {left: ${leftPos};}`
  }
}

// slides

const slideUpdater = () => {
  let slideObj = []
  return {
    clear: () => {
      slideObj = []
    },
    removeDuplicates: res => {
      slideObj.forEach(a => {
        if (a.question === res.question) {
          slideObj.splice(slideObj.indexOf(a))
        }
      })
      return slideObj
    }
  }
}
const updateSlides = slideUpdater()

function updateSlideObj(set, x, y, v) {
  set = {
    question: x,
    answer: y,
    value: v
  }
  const updatedSet = updateSlides.removeDuplicates(set)
  updatedSet.push(set)

  if (checkCompletion(updatedSet)) {
    resultsShow()
    resultsData(updatedSet)
    raiseFormEvent()
  }
}

function resultsShow() {
  const rollEval = document.querySelector('.rollover-evaluator')
  const rollEvalRes = document.querySelector('.rollover-results-view')
  const resultsSummary = document.querySelector('.rollover-results-summary')
  const bottomContent = document.querySelector('.bottom-content')
  const advisor = document.querySelector('.advisor-gallery-compound')
  rollEval.style.display =
    !rollEval.style.display || rollEval.style.display === 'block'
      ? 'none'
      : 'block'
  rollEvalRes.style.display =
    !rollEvalRes.style.display || rollEvalRes.style.display === 'none'
      ? 'block'
      : 'none'
  resultsSummary.classList.remove('u-hidden')
  bottomContent.classList.remove('u-hidden')
  advisor.classList.remove('u-sm-hidden')
}

function raiseFormEvent() {
  const resultsSummary = document.querySelector('.rollover-results-summary')
  const e = new CustomEvent('resultsShow', {
    detail: { html: callEmailHtml() }
  })
  resultsSummary.dispatchEvent(e)
}

function callEmailHtml() {
  /* return form element from the results */
  const resultsDOM = document.querySelector(
    '#rollover-results-download-content-pdf'
  )
  if (resultsDOM) {
    const resultsHTML = resultsDOM.innerHTML || ''
    const formData = emailForm(resultsHTML)
    return formData
  }
}

function restartSlides() {
  const rollEval = document.querySelector('.rollover-evaluator')
  const rollEvalRes = document.querySelector('.rollover-results-view')
  const resultsSummary = document.querySelector('.rollover-results-summary')
  const bottomContent = document.querySelector('.bottom-content')
  const advisor = document.querySelector('.advisor-gallery-compound')
  rollEvalRes.style.display = 'none'
  rollEval.style.display = 'block'
  resultsSummary.classList.add('u-hidden')
  bottomContent.classList.add('u-hidden')
  advisor.classList.add('u-sm-hidden')
  updateSlides.clear()
  document
    .querySelectorAll(".swiper-slide[data-target='card']")
    .forEach((slide, index) => {
      if (index !== 0) {
        slide.setAttribute('hidden', '')
      }
    })
  findSlides()
}

function findActive() {
  return document.querySelector(".swiper-slide.active[data-target='card']")
}

function findSlides() {
  const activeSlide = findActive()
  if (activeSlide) {
    const slideQ = activeSlide.querySelector('.rollover-question').classList

    if (slideQ.contains('Radio-Buttons')) {
      handleRadio()
    } else if (slideQ.contains('Date-Field')) {
      handleDate()
    } else if (slideQ.contains('Integer-Field')) {
      handleInteger()
    } else {
      enableSwipe()
    }
  }
}

function handleDate() {
  const activeSlide = findActive()
  const dobData = data.questions.filter(d => d.name === 'dateOfBirth')
  slideInputData(activeSlide, dobData[0])
  const dateRegex =
    /^((0?[1-9]|1[012])[/](0?[1-9]|[12][0-9]|3[01])[/](19|20)?[0-9]{4})$/
  const dateInput = activeSlide.querySelector('input')
  let currentChar = 0
  if (dateInput.value) {
    enableSwipe()
  }

  dateInput.addEventListener('keyup', e => {
    const updatedValue = e.target.value
    const dateTest = dateRegex.test(dateInput.value)
    if (updatedValue.length > currentChar) {
      dateInput.value = addSlash(updatedValue)
        ? updatedValue + '/'
        : updatedValue
    }
    currentChar = updatedValue.length
    if (dateTest) {
      enableSwipe()
      toggleError(activeSlide)
    } else {
      disableSwipe()
    }
  })

  dateInput.addEventListener('focusout', () => {
    const dateTest = dateRegex.test(dateInput.value)
    if (dateTest) {
      toggleError(activeSlide)
    } else {
      toggleError(activeSlide, false)
      disableSwipe()
    }
  })
}

function addSlash(value = '') {
  return value && (value.length === 2 || value.length === 5)
}

function slideInputData(activeSlide, dataObj) {
  const error = activeSlide.querySelector('.error')
  const hint = activeSlide.querySelector('.input-text')
  error.innerHTML = dataObj.errorMessage
  hint.innerHTML = dataObj.options.title
}

function handleRadio() {
  const activeSlide = findActive()
  const radioInput = activeSlide.querySelectorAll('input')
  radioInput.forEach(input => {
    input.addEventListener('click', () => {
      input.setAttribute('selected', '')
      enableSwipe()
    })
  })
  if (activeSlide.querySelector('input[selected]')) {
    enableSwipe()
  }
}

function handleInteger() {
  const numberRe = /^[0-9]+$/
  const activeSlide = findActive()
  const integerData = data.questions.filter(d => d.type === 'number')
  slideInputData(activeSlide, integerData[0])
  const intInput = activeSlide.querySelector('input')
  if (intInput.value) {
    enableSwipe()
  }
  const validateAgeInput = (
    _e,
    enabledCallback = () => {},
    disabledCallback = () => {}
  ) => {
    if (
      intInput.value &&
      intInput.value.match(numberRe) &&
      intInput.value.length < 4
    ) {
      enableSwipe()
      enabledCallback()
    } else {
      disableSwipe()
      disabledCallback()
    }
  }
  intInput.addEventListener('keyup', validateAgeInput)
  intInput.addEventListener('focusout', e =>
    validateAgeInput(
      e,
      () => toggleError(activeSlide),
      () => toggleError(activeSlide, false)
    )
  )
}

function toggleError(activeSlide, hidden = true) {
  const error = activeSlide.querySelector('.error')
  error.hidden = hidden
}

function enableSwipe() {
  const swiperContainer = document.querySelector(
    '.swiper-container.rollover-swiper'
  )
  const swiperNav = swiperContainer.querySelector('.swiper-button-next')
  if (swiperNav.classList.contains('swiper-button-disabled')) {
    swiperNav.classList.remove('swiper-button-disabled')
    swiperNav.removeAttribute('disabled')
  }
}
function disableSwipe() {
  const swiperContainer = document.querySelector(
    '.swiper-container.rollover-swiper'
  )
  const swiperNav = swiperContainer.querySelector('.swiper-button-next')
  swiperNav.classList.add('swiper-button-disabled')
  swiperNav.setAttribute('disabled', true)
}

function getRes(slide) {
  const qstn = slide.querySelector('.rollover-question').dataset.name
  const inputTypes = slide.querySelectorAll('input')
  let selectedValue
  let selectedName
  let userRes

  inputTypes.forEach(input => {
    if (input.type === 'radio') {
      for (const radio of inputTypes) {
        if (radio.checked) {
          selectedValue = radio.value
          selectedName = radio.name
          userRes = slide.querySelector('input').innerHTML
          break
        }
      }
    } else if (input.type === 'text' || input.type === 'number') {
      selectedName = slide.querySelector('input').name
      selectedValue = slide.querySelector('input').value
      userRes = slide.querySelector('input').innerHTML
    }
  })
  updateSlideObj(selectedName, qstn, userRes, selectedValue)
}

// results

const processResults = () => {
  let results = []
  return (question, answer, value) => {
    if (question.type === 'number') {
      results.push(resultsObjNum(question, answer, value))
    } else if (question.type === 'radio') {
      results.push(resultsObjRadio(question, answer, value))
    } else if (question.type === 'text') {
      results.push(resultsObjText(question, answer, value))
    }
    if (checkCompletion(results)) {
      downloadResults(results)
      newSlides(results)
      createResultsView(data, results)
      results = []
    }
  }
}
const resultsObj = processResults()

function resultsObjNum(
  question,
  answer = { title: '', description: ['', ''] },
  value
) {
  return {
    question: question.title,
    resLabel: question.descriptionLabel,
    resTitle: value,
    desc: parseInt(value) >= 60 ? answer.description2 : answer.description
  }
}

function resultsObjRadio(
  question,
  answer = {
    answerLabel: '',
    title: '',
    value: '',
    resultsIRARollOverPros: '',
    resultsLeaveAssetsCons: '',
    description: ['']
  }
) {
  return {
    question: question.title,
    resLabel: question.descriptionLabel,
    res: !answer.answerLabel ? answer.title : answer.answerLabel,
    resTitle: answer.title,
    desc: answer.description[0],
    value: answer.value,
    rollOverIRA: answer.resultsIRARollOverPros,
    leaveAssetsInCurrentPlan: answer.resultsLeaveAssetsCons
  }
}

function resultsObjText(
  question,
  answer = { title: '', description: [''] },
  value
) {
  return {
    question: question.title,
    resLabel: question.descriptionLabel,
    res: value,
    resTitle: answer.title,
    desc: answer.description[0]
  }
}

export function resultsData(arr) {
  arr.forEach(slide => {
    const dataQuestions = data.questions
    const filteredRes = dataQuestions.filter(
      question => question.name === slide.question
    )
    filteredRes.forEach(res => {
      if (res.options.length >= 1) {
        const filtersOptions = res.options.filter(
          opt => opt.value === slide.value
        )
        resultsObj(filteredRes[0], filtersOptions[0])
      } else {
        resultsObj(filteredRes[0], res.options, slide.value)
      }
    })
  })
}

export function createResults(res) {
  res.forEach(slide => {
    const newCard = document.createElement('div')
    const newq = document.createElement('h3')
    const newRes = document.createElement('p')
    const newDesc = document.createElement('p')
    newq.appendChild(document.createTextNode(slide.question))
    newRes.appendChild(document.createTextNode(slide.answer))
    const resultsDiv = document.querySelector('.results')

    newCard.classList.add('swiper-slide')
    newCard.appendChild(newq)
    newCard.appendChild(newRes)
    newCard.appendChild(newDesc)
    resultsDiv.appendChild(newCard)
  })
}

export function downloadResults(results) {
  const resultsViewHTMLDownload = document.querySelector(
    '.rollover-results-download-content .swiper-wrapper'
  )
  const resultsLengthDownload = results.length
  resultsViewHTMLDownload.innerHTML = ''
  results.forEach((result, index) => {
    const pageDownload = index + 1
    const newResultDownload = document.createElement('div')
    newResultDownload.setAttribute('data-target', 'card')
    newResultDownload.innerHTML = `
    <div>
      <div class="wrapper">
        <div class="pagination">
            <h5 class="results-pagination u-textSizeMsrp1">Question ${pageDownload}/${resultsLengthDownload}</h5>
        </div>
        <div class="rollover-question-download u-paddingBottom3gu">
          <h3></h3>
            <p class="your-response u-paddingTop2gu"></p>
        </div>
      </div>
      <div class="Content rollover-response response-description">
        <p class="u-paddingTop2gu"></p>
      </div>
    </div>`

    const resQuestionDownload = newResultDownload.querySelector(
      '.rollover-question-download h3'
    )
    const resUserResponseDownload = newResultDownload.querySelector(
      '.rollover-question-download p'
    )
    const resDescDownload = newResultDownload.querySelector(
      '.response-description p'
    )
    const paginationDownload = newResultDownload.querySelector(
      '.results-pagination'
    )
    paginationDownload.InnerHTML = index
    resQuestionDownload.innerHTML = result.question

    if (result.desc == null) {
      resDescDownload.innerText = result.resLabel + ' ' + result.res
    } else {
      Array.isArray(result.desc)
        ? (resDescDownload.innerHTML = `${result.desc[0]}</br>${result.desc[1]}`)
        : (resDescDownload.innerHTML = result.desc)
      !result.resLabel.includes(':')
        ? (resUserResponseDownload.innerText =
            result.resLabel + ' ' + result.res)
        : (resUserResponseDownload.innerText =
            result.resLabel + ' ' + result.resTitle)
    }
    resultsViewHTMLDownload.appendChild(newResultDownload)
  })
}

export function newSlides(results) {
  const resultsViewHTML = document.querySelector(
    '.rollover-results-view .swiper-wrapper'
  )
  const resultsLength = results.length

  results.forEach((result, index) => {
    const page = index + 1
    const newSlide = document.createElement('div')
    newSlide.setAttribute('data-target', 'card')
    newSlide.className = 'swiper-slide u-posRelative u-flexShrink0 u-sizeFull'
    newSlide.innerHTML = `
        <div class="Content Card slide-content card-height u-bgColorWhite u-flex u-md-flexCol u-sm-flexCol u-md-sizeFull u-padding0 u-decoBorderLightFull">
          <div class="u-size6of12 u-md-sizeFull u-sm-sizeFull u-md-paddingVert5gu u-sm-paddingVert5gu arrow-right u-posRelative u-flex u-flexCol u-flexJustifyCenter">
            <div class="u-posRelative slide-height question-slide-summary u-flex u-md-flex u-sm-flex u-flexCol u-flexJustifyCenter">
              <div class="Content pagination u-flex u-paddingHoriz8gu">
                <p class="u-paddingHoriz2gu u-paddingBottom8gu">Question</p>
                <p class="results-pagination">${page}/${resultsLength}</p>
              </div>
              <div class="Content rollover-question u-paddingHoriz10gu u-marginCenter">
                <h3></h3>
                <div class="u-marginCenter u-textCenter">
                  <p class="your-response questionFont u-textOpen u-marginCenter u-paddingVert5gu u-textBold u-textSizeMsrp1 u-sm-textSizeMsrm1 u-inlineBlock u-textLeft"></p>
                </div>
              </div>
            </div>
            </div>
            <div class="slide-height u-flex u-flexCol u-flexJustifyCenter u-paddingRight10gu u-sm-paddingLeft0 u-paddingLeft5gu u-size6of12 u-bgColorAdele u-md-sizeFull u-sm-sizeFull">
              <div class="Content Content--reversed rollover-response u-flex u-flexCol u-flexAlignItemsCenter u-marginCenter response-description">
                <p class="u-textColorWhite u-textSizeMsrp1 u-sm-textSizeMsrm1 u-sizeFull u-sm-textDefault"></p>
              </div>
            </div>
          </div>
        </div>`

    const resQuestion = newSlide.querySelector('.rollover-question h3')
    const resUserResponse = newSlide.querySelector('.rollover-question p')
    const resDesc = newSlide.querySelector('.response-description p')
    const pagination = newSlide.querySelector('.results-pagination')
    pagination.InnerHTML = index
    resQuestion.innerHTML = result.question

    if (result.desc == null) {
      resDesc.innerText = result.resLabel + ' ' + result.res
    } else {
      Array.isArray(result.desc)
        ? (resDesc.innerHTML = `${result.desc[0]}</br>${result.desc[1]}`)
        : (resDesc.innerHTML = result.desc)
      !result.resLabel.includes(':')
        ? (resUserResponse.innerText = result.resLabel + ' ' + result.res)
        : (resUserResponse.innerText = result.resLabel + ' ' + result.resTitle)
    }
    resultsViewHTML.appendChild(newSlide)
    dfnToolTIp(resQuestion, data)
  })
}

export function createResultsView(data, resultsObj) {
  const resultsView = document.querySelector('.amp-ira-rollover-results')
  const resultTabsData = data.results.tabs
  document.querySelector('.results-summary-heading').innerHTML =
    data.results.title
  document.querySelector('.results-summary p').innerHTML = data.results.overview
  document.querySelector('.options-title').innerHTML = data.results.subheading
  document.querySelector('.rollover-results-summary h3').innerHTML =
    data.results.summaryHeading

  createResultTabs(resultTabsData, resultsView, resultsObj)
  resultsTabsSelection(resultTabsData, resultsView, resultsObj)
}

const createResultTabs = (resultTabsData, resultsView, resultsObj) => {
  const listTabsClassList =
    'listTabs List-item marginCenter u-marginHoriz6gu u-textSizeMsr'
  const ulListTabs = resultsView.querySelector('div.tab-titles')
  const dropTabs = resultsView.querySelector('select.Dropdown-menu')

  resultTabsData.forEach(result => {
    const newTab = document.createElement('p')
    const newOption = document.createElement('option')
    newTab.setAttribute('role', 'tab')
    newTab.setAttribute('data-value', result.name)
    newOption.setAttribute('data-value', result.name)
    newOption.setAttribute('value', result.name)
    newTab.className = listTabsClassList
    newOption.classList.add('optionTabs')
    newOption.innerHTML = result.title
    newTab.innerHTML = result.title
    ulListTabs.appendChild(newTab)
    dropTabs.appendChild(newOption)
  })
  showThirdTab(resultsObj)
}

const showThirdTab = responses => {
  const thirdTab = document.querySelectorAll(
    '[data-value="rollOverToNewEmployer"]'
  )
  const tabOptions = responses.filter(
    userRes =>
      userRes.value === 'changingJobs' || userRes.value === 'previousEmployer'
  )
  if (tabOptions.length <= 0) {
    thirdTab.forEach(third => {
      third.style.display = 'none'
    })
  }
}

const createResultProsCONS = (
  resultTabsData,
  resultsView,
  activeTab,
  resultsObj
) => {
  const ulPros = resultsView.querySelector('ul.pros')
  const ulCons = resultsView.querySelector('ul.cons')
  const dataValue = activeTab.dataset.value
  const activeTabData = resultTabsData.filter(tabs => tabs.name === dataValue)
  const resOverView = document.querySelector('.results-overview')
  const activeTabEntry = activeTabData[0]

  if (activeTabEntry.overview) {
    activeTabEntry.overview.forEach(overView => {
      const newOverview = document.createElement('p')
      newOverview.innerHTML = overView
      resOverView.appendChild(newOverview)
      dfnToolTIp(resOverView, data)
    })
  }

  const proList = activeTabEntry.pros.list
  const proOverview = activeTabEntry.pros.overview
  createList(proList, ulPros, proOverview)
  const conList = activeTabEntry.cons.list
  const conOverview = activeTabEntry.cons.overview
  createList(conList, ulCons, conOverview)

  resultsObj.forEach(obj => {
    if (obj.rollOverIRA || obj.leaveAssetsInCurrentPlan) {
      if (dataValue === 'rollOverIRA' && obj.rollOverIRA !== '') {
        createList(obj.rollOverIRA, ulPros)
      } else if (
        dataValue === 'leaveAssetsInCurrentPlan' &&
        obj.leaveAssetsInCurrentPlan !== ''
      ) {
        createList(obj.leaveAssetsInCurrentPlan, ulCons)
      }
    }
  })
}

const createResultProsCONSDownload = (resultTabsData, resultsObj) => {
  const downloadTitles = [
    'rollOverIRA',
    'leaveAssetsInCurrentPlan',
    'rollOverToNewEmployer'
  ]
  const resultsSections = document.querySelector('.result-title-section')
  let activeTabDataDownload = ''
  let activeTitle = ''
  let activeTabEntryDownload = {}
  let sectionCount = 3

  resultsSections.innerHTML = ''

  const tabOptions = resultsObj.filter(
    userRes =>
      userRes.value === 'changingJobs' || userRes.value === 'previousEmployer'
  )
  if (tabOptions.length <= 0) {
    sectionCount = 2
  }

  for (let i = 0; i < sectionCount; i++) {
    activeTitle = downloadTitles[i]
    activeTabDataDownload = resultTabsData.filter(
      tabs => tabs.name === activeTitle
    )
    activeTabEntryDownload = activeTabDataDownload[0]

    const ulWrapperElement = document.createElement('div')
    ulWrapperElement.className = 'page-break'
    resultsSections.appendChild(ulWrapperElement)
    if (activeTabEntryDownload.title) {
      const newTitle = document.createElement('h2')
      newTitle.className = 'u-paddingTop5gu u-paddingBottom3gu u-textBold'
      newTitle.innerHTML = activeTabEntryDownload.title
      ulWrapperElement.appendChild(newTitle)

      activeTabEntryDownload.overview.forEach(overView => {
        const newOverview = document.createElement('p')
        newOverview.className = 'u-paddingBottom4gu'
        newOverview.innerHTML = overView
        ulWrapperElement.appendChild(newOverview)
      })

      const proTitle = document.createElement('h5')
      proTitle.className = 'u-textSizeMsrp1 u-paddingBottom2gu'
      proTitle.innerHTML = 'Pros'
      ulWrapperElement.appendChild(proTitle)

      if (activeTabEntryDownload.pros.overview) {
        const newProsOverview = document.createElement('p')
        newProsOverview.className = 'u-marginBottom2gu pros-overview'
        newProsOverview.innerHTML = activeTabEntryDownload.pros.overview
        ulWrapperElement.appendChild(newProsOverview)
      }

      const newProsUL = document.createElement('ul')
      newProsUL.className = 'pros'
      ulWrapperElement.appendChild(newProsUL)

      const proListDownload = activeTabEntryDownload.pros.list
      downloadList(proListDownload, newProsUL)

      const conTitle = document.createElement('h5')
      conTitle.className = 'u-textSizeMsrp1 u-paddingTop2gu u-paddingBottom2gu'
      conTitle.innerHTML = 'Cons'

      const conListDownload = activeTabEntryDownload.cons.list
      ulWrapperElement.appendChild(conTitle)

      const newCosUL = document.createElement('ul')
      newCosUL.className = 'cons'
      ulWrapperElement.appendChild(newCosUL)
      downloadList(conListDownload, newCosUL)
    }
  }
}

function downloadList(listItems, ul) {
  if (Array.isArray(listItems)) {
    listItems.forEach(item => {
      const newItem = document.createElement('li')
      newItem.className = 'u-paddingLeft2gu u-paddingBottom1gu'
      newItem.innerHTML = item
      ul.appendChild(newItem)
    })
  } else {
    const newItem = document.createElement('li')
    newItem.className = 'u-paddingLeft2gu u-paddingBottom1gu'
    newItem.innerHTML = listItems
    ul.appendChild(newItem)
  }
}

function createList(listItems, ul, listDesc) {
  if (listDesc) {
    if (ul.previousElementSibling) {
      ul.previousElementSibling.innerHTML = listDesc
    }
  }

  if (Array.isArray(listItems)) {
    listItems.forEach(item => {
      const newItem = document.createElement('li')
      newItem.innerHTML = item
      ul.appendChild(newItem)
    })
  } else {
    const newItem = document.createElement('li')
    newItem.innerHTML = listItems
    ul.appendChild(newItem)
  }
  dfnToolTIp(ul, data)
}

export function resultsTabsSelection(resultTabsData, resultsView, resultsObj) {
  const resTabs = document.querySelectorAll('.listTabs')
  const activeTabInit = document.querySelector(
    '.tab-titles .listTabs:first-of-type'
  )
  const activeDropdownInit = document.querySelector(
    '.Dropdown-menu .optionTabs:first-of-type'
  )
  const selectedClassList =
    'listTabs List-item marginCenter u-marginHoriz6gu u-textSizeMsr is-selected u-textBold'
  const tabsClassList =
    'listTabs List-item marginCenter u-marginHoriz6gu u-textSizeMsr'
  activeTabInit.className = selectedClassList
  activeDropdownInit.classList.add('is-selected')
  activeDropdownInit.setAttribute('selected', 'selected')
  createResultProsCONS(resultTabsData, resultsView, activeTabInit, resultsObj)
  createResultProsCONSDownload(resultTabsData, resultsObj)

  resTabs.forEach(tab => {
    tab.addEventListener('click', e => {
      e.preventDefault()
      clearTabResults(resultsView)
      const activeTab = document.querySelector('.listTabs.is-selected')
      activeTab.className = tabsClassList
      e.target.className = selectedClassList
      createResultProsCONS(resultTabsData, resultsView, e.target, resultsObj)
      createResultProsCONSDownload(resultTabsData, resultsObj)
    })
  })
  const menu = document.querySelector('.Dropdown-menu')
  menu.addEventListener('change', e => {
    e.preventDefault()
    clearTabResults(resultsView)
    const activeTab = menu.querySelector('.optionTabs.is-selected')
    activeTab.classList.remove('is-selected')
    const targetValue = `[data-value=${e.target.value}]`
    const selectedTab = resultsView.querySelector(targetValue)
    selectedTab.classList.add('is-selected')
    createResultProsCONS(resultTabsData, resultsView, selectedTab, resultsObj)
    createResultProsCONSDownload(resultTabsData, resultsObj)
  })
}

export function clearTabResults(resultsView) {
  const ulPros = resultsView.querySelector('ul.pros')
  const ulCons = resultsView.querySelector('ul.cons')
  const overview = resultsView.querySelector('.pros-overview')
  const resOverView = document.querySelector('.results-overview')
  ulPros.innerHTML = ''
  ulCons.innerHTML = ''
  overview.innerHTML = ''
  resOverView.innerHTML = ''
}

export function clearResultsPage() {
  clearTabResults(document.querySelector('.amp-ira-rollover-results'))
  document.querySelector('div.tab-titles').innerHTML = ''
  document.querySelector('.Dropdown-menu').innerHTML = ''
  document
    .querySelectorAll('.swiper-container.results .swiper-wrapper .swiper-slide')
    .forEach(slide => slide.parentNode.removeChild(slide))
}

export function checkCompletion(arr) {
  const totalSlides = document.querySelectorAll(
    '.rollover-evaluator .swiper-slide'
  )
  return totalSlides.length === arr.length
}

export function restartResultsSlider(slider) {
  slider.slideTo(0)
  const prevButton = document.querySelector(
    '.rollover-results-summary .swiper-button-prev'
  )
  prevButton.classList.add('swiper-button-disabled')
  prevButton.parentNode.replaceChild(prevButton.cloneNode(true), prevButton)

  const nextButton = document.querySelector(
    '.rollover-results-summary .swiper-button-next'
  )
  nextButton.classList.remove('swiper-button-disabled')
  nextButton.parentNode.replaceChild(nextButton.cloneNode(true), nextButton)
}

export {
  restartSlides,
  findActive,
  findSlides,
  enableSwipe,
  disableSwipe,
  getRes,
  addSlash,
  handleDate,
  handleInteger,
  tooltipListener,
  dfnToolTIp,
  aboutRolloverTooltip
}
