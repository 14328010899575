// Back To Top Button
export default class BackToTop {
  constructor() {
    this.backToTopBtn = document.getElementById('send-to-top')
    if (!this.backToTopBtn) {
      return
    }
    this.top = document.getElementById('back-to-top-target')
    this.backToTopFooterBtn = document.getElementById('send-to-top-footer-link')
    this.parallaxDiv = document.querySelector('html')
    this.footerElement = document.querySelector('footer')
    this.header = document.querySelector('header')
    this.oldScroll = 0

    if (!this.backToTopBtn) {
      return
    }
    this.triggerScrollEvent = this.triggerScrollEvent.bind(this)
    this.onScroll = this.onScroll.bind(this)
    this.checkFooter = this.checkFooter.bind(this)
    this.init()
  }

  init() {
    window.addEventListener('scroll', this.onScroll)
    this.backToTopBtn.addEventListener('click', this.triggerScrollEvent)
  }

  triggerScrollEvent() {
    console.log('scroll')
    this.parallaxDiv.scrollTop = 0
    this.top.focus()
    this.header.setAttribute('style', `transform: translateY(0)`)
  }

  // Show back-to-top button When user scrolls up when at least 3000px down the page
  onScroll() {
    const shouldShow =
      this.parallaxDiv.scrollTop > 3000 &&
      this.oldScroll > this.parallaxDiv.scrollTop
    this.oldScroll = this.parallaxDiv.scrollTop
    if (shouldShow) {
      this.backToTopBtn.classList.remove('-hide')
      this.backToTopBtn.classList.add('-show')
      return
    }

    this.backToTopBtn.classList.add('-hide')
    this.backToTopBtn.classList.remove('-show')
  }

  checkFooter() {
    if (
      this.footerElement &&
      this.footerElement.getBoundingClientRect().top < window.innerHeight - 45
    ) {
      this.backToTopBtn.style.display = 'none'
      return
    }
    this.backToTopBtn.style.display = 'inherit'
  }
}

/* eslint-disable no-new */
new BackToTop()
