import jsCookie from 'js-cookie'

const ameripriseAdvisorsMatch = 'ameripriseadvisors.com'
const queryParameterName = 'lastAmpAuthState'

// Returns the new url with our new query parameter.
function updateQueryStringParameter(uri, key, value) {
  if (uri) {
    var re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i')
    var separator =
      uri && uri.indexOf('?') && uri.indexOf('?') !== -1 ? '&' : '?'
    if (uri.match(re)) {
      return uri.replace(re, '$1' + key + '=' + value + '$2')
    } else {
      return uri + separator + key + '=' + value
    }
  }
}

// Gets the value for for lastAmpAuthState from the cookie.
export const getLastAmpAuthState = function () {
  if (window.location.hostname === 'localhost') {
    jsCookie.set('lastAmpAuthState', 'unrecognized')
  }
  const lastAmpAuthState = jsCookie.get('lastAmpAuthState')
  return lastAmpAuthState || 'unrecognized'
}

// Searches for all ameripriseadvisors hrefs/links on the page
// Then it adds the new lastAmpAuthState query parameter to the url.
export function getLinksOnPage() {
  const lastAmpAuthState = getLastAmpAuthState()
  const queryselector = "[href*='" + ameripriseAdvisorsMatch + "']"
  var links = document.querySelectorAll(queryselector)
  links.forEach(link => {
    link.href = updateQueryStringParameter(
      link.href,
      queryParameterName,
      lastAmpAuthState
    )
  })
}

export const getAdvisorLinkWithCookie = url => {
  const authState = getLastAmpAuthState()
  const update = updateQueryStringParameter(url, queryParameterName, authState)
  return update
}

window.onload = getLinksOnPage()
