import React from 'react'

import SearchApp from './SearchApp'
import dust from './SearchHelpers'

import './Search.less'
import xss from 'xss'

export default class Search extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      error: '',
      data: {},
      paginationData: {},
      searchScrubbed: 0,
      queryTermsRegExp: '',
      urlHostPage: '',
      isLoaded: false
    }

    this.dataCallback = this.dataCallback.bind(this)
  }

  componentDidMount() {
    new SearchApp().init(
      {
        $rootElement: {},
        instance: {},
        settings: {}
      },
      this.dataCallback
    )
  }

  dataCallback(data) {
    if (data.error) {
      this.setState({
        error: data.error,
        isLoaded: true
      })
    } else {
      this.setState({
        error: '',
        data: data.data,
        paginationData: data.paginationData,
        searchScrubbed: data.searchScrubbed,
        queryTermsRegExp: data.queryTermsRegExp,
        urlHostPage: data.urlHostPage,
        isLoaded: true
      })
    }
  }

  renderFilterButton(resolvedCategory, urlHostPage, userQuery) {
    return resolvedCategory ? (
      <li className="u-marginTop1gu">
        {resolvedCategory && (
          <div>
            <span className="u-textBold u-textDivider">{resolvedCategory}</span>
            <a
              className="u-marginLeft1gu"
              href={
                urlHostPage +
                '?sp_q=' +
                encodeURIComponent(userQuery) +
                '&sp_q_1=' +
                encodeURIComponent(resolvedCategory)
              }
            >
              Show only this category
            </a>
          </div>
        )}
      </li>
    ) : null
  }

  // if property named {propertyName} exists then return value for {valueKey}
  findPropertyValue(entities, propertyKey, propertyName, valueKey) {
    for (var i = 0; i < entities.length; i++) {
      if (entities[i][propertyKey] === propertyName) {
        return entities[i][valueKey]
      }
    }
  }
  renderResult(searchResult, urlHostPage, userQuery) {
    var type =
      searchResult.properties.extracted_metadata.file_type.toLowerCase() || ''
    var resolvedUrl = xss(searchResult.metadata.source.url)
    const categoryUnsafe = searchResult.properties.enriched_html
      ? this.findPropertyValue(
          searchResult.properties.enriched_html[0].entities,
          'model_name',
          'Category',
          'text'
        )
      : ''
    var resolvedCategory = xss(categoryUnsafe)
    var resolvedTitle =
      (searchResult.properties.enriched_html &&
        this.findPropertyValue(
          searchResult.properties.enriched_html[0].entities,
          'model_name',
          'Omni_Title',
          'text'
        )) ||
      (searchResult.properties.extracted_metadata &&
        searchResult.properties.extracted_metadata.title) ||
      (searchResult.properties.title &&
        searchResult.properties.title.split('|')[0]) ||
      searchResult.properties.extracted_metadata.filename
    resolvedTitle = xss(
      dust.helpers.queryMark({
        content: resolvedTitle,
        queryTermsRegExp: this.state.queryTermsRegExp
      })
    )
    const descUnsafe =
      (searchResult.properties.enriched_html &&
        this.findPropertyValue(
          searchResult.properties.enriched_html[0].entities,
          'model_name',
          'description',
          'text'
        )) ||
      (searchResult.properties.extracted_metadata &&
        searchResult.properties.extracted_metadata.subject) ||
      ''
    var resolvedDescription = xss(
      dust.helpers.queryMark({
        content: descUnsafe,
        queryTermsRegExp: this.state.queryTermsRegExp
      })
    )
    var passage =
      searchResult.documentPassages &&
      xss(searchResult.documentPassages[0].passageText)

    if (type === 'pdf') {
      return (
        <li key={'searchResult' + searchResult.documentId} className="sr-pdf">
          <h2>
            <a
              href={resolvedUrl}
              dangerouslySetInnerHTML={{ __html: xss(resolvedTitle) }}
            />
          </h2>
          {this.renderFilterButton(resolvedCategory, urlHostPage, userQuery)}
          <p
            className="u-marginTop1gu sr-primary-text"
            dangerouslySetInnerHTML={{
              __html: xss(resolvedDescription || passage)
            }}
          />
        </li>
      )
    } else if (resolvedCategory.toLowerCase() === 'financial advisors') {
      return (
        <li
          key={'searchResult' + searchResult.documentId}
          className="sr-advisor-team"
        >
          <div className="row">
            <div className="advisor-header col-xs-12">
              <h2>
                <a
                  className="u-textSizeMsrp2"
                  href={resolvedUrl}
                  dangerouslySetInnerHTML={{ __html: xss(resolvedTitle) }}
                />
              </h2>
              <p className="u-marginTop1gu">{searchResult.advisorTitle}</p>
            </div>
          </div>
          {this.renderFilterButton(resolvedCategory, urlHostPage, userQuery)}
        </li>
      )
    } else {
      return (
        <li
          key={'searchResult' + searchResult.documentId}
          className="sr-standard"
        >
          <h2>
            <a
              href={resolvedUrl}
              dangerouslySetInnerHTML={{ __html: xss(resolvedTitle) }}
            />
          </h2>
          {this.renderFilterButton(resolvedCategory, urlHostPage, userQuery)}
          <p
            className="u-marginTop1gu sr-primary-text"
            dangerouslySetInnerHTML={{ __html: xss(resolvedDescription) }}
          />
        </li>
      )
    }
  }

  render() {
    const { searchScrubbed, urlHostPage, error, isLoaded } = this.state

    const {
      searchResults,
      suggestions,
      resultsLower,
      resultsUpper,
      resultsTtl,
      userQuery
    } = this.state.data
    const userQueryDecoded = decodeURIComponent(userQuery)

    const { currentSearchUrl, pages, previous, next } =
      this.state.paginationData

    return (
      <div id="site-search-results" className="search-component">
        <section className="u-sizeConstrained">
          <div className="row sr-title-summary">
            <div className="col-xs-12 summary-container">
              <h1>Search Results</h1>
              {!isLoaded && (
                <div className="u-flex u-flexCol u-flexJustifyCenter u-flexAlignItemsCenter loadingDiv">
                  <div className="Loading u-marginLeft1gu u-textSizeMsrp2">
                    <div className="Loading-indicator u-bgColorBrandLight"></div>
                    <div className="Loading-indicator u-bgColorBrandLight"></div>
                    <div className="Loading-indicator u-bgColorBrandLight"></div>
                  </div>
                </div>
              )}
              {searchResults && searchResults.length > 0 ? (
                <p className="u-marginTop3gu">
                  Showing results {resultsLower}-
                  {resultsUpper >= resultsTtl ? resultsTtl : resultsUpper} of{' '}
                  {resultsTtl} for: <mark>{userQueryDecoded}</mark>
                </p>
              ) : null}
              {(!searchResults && !error && userQuery) ||
              (searchResults &&
                searchResults.length === 0 &&
                !error &&
                userQuery) ? (
                <p className="u-marginTop3gu suggestions">
                  Nothing found for: <mark>{userQueryDecoded}</mark>
                </p>
              ) : null}
              {!searchResults && suggestions
                ? suggestions.map((suggestion, index) => {
                    return (
                      <p key={'suggestion' + index} className="u-marginTop3gu">
                        Did you mean{' '}
                        <a
                          href={
                            '?sp_q=' +
                            dust.filters.enc(
                              dust.filters.ramps(suggestion.suggestedQuery)
                            )
                          }
                        >
                          {dust.filters.ramps(suggestion.suggestedQuery)} (
                          {suggestion.count})
                        </a>
                      </p>
                    )
                  })
                : null}
              {searchScrubbed ? (
                <p className="u-marginTop3gu search-cleaned">
                  For security reasons, some of the characters in your search
                  query were automatically removed.
                </p>
              ) : null}
              <hr />
            </div>
          </div>
          {searchResults && searchResults.length > 0 ? (
            <>
              <div className="row search-results-container">
                <div className="col-xs-12">
                  <ul className=" Content search-results-list">
                    {searchResults.map(searchResult =>
                      this.renderResult(searchResult, urlHostPage, userQuery)
                    )}
                  </ul>
                </div>
              </div>
              <hr />
              <div className="row sr-pagination">
                <div className="col-xs-12 u-flex u-flexAlignItemsEnd">
                  {previous.pageStartItemIndex > 0 ? (
                    <div className="amp-caret amp-caret-left u-textColorBrandLight">
                      &lsaquo;
                    </div>
                  ) : null}
                  <div className="u-flex">
                    {previous.pageStartItemIndex > 0 ? (
                      <a
                        className="adjacent-page-link u-marginRight2gu"
                        href={
                          urlHostPage +
                          currentSearchUrl +
                          '&sp_n=' +
                          (parseInt(previous.pageStartItemIndex) - 1)
                        }
                      >
                        Previous
                      </a>
                    ) : null}
                    <div className="page-number-links hidden-xs">
                      {pages.map(page => {
                        if (page.currentPageClass !== 'current-page') {
                          return (
                            <a
                              key={page.pageNumber}
                              className="u-decoBorderLightFull u-marginHoriz2gu u-paddingVert2gu"
                              href={
                                urlHostPage +
                                currentSearchUrl +
                                '&sp_n=' +
                                page.pageStartItemIndex
                              }
                            >
                              {page.pageNumber}
                            </a>
                          )
                        } else {
                          return (
                            <span
                              key={page.pageNumber}
                              className={page.currentPageClass}
                            >
                              {page.pageNumber}
                            </span>
                          )
                        }
                      })}
                    </div>
                    {next.pageStartItemIndex > 0 ? (
                      <a
                        className="adjacent-page-link u-marginLeft2gu"
                        href={
                          urlHostPage +
                          currentSearchUrl +
                          '&sp_n=' +
                          next.pageStartItemIndex
                        }
                      >
                        Next
                      </a>
                    ) : null}
                  </div>
                  {next.pageStartItemIndex > 0 ? (
                    <div className="amp-caret amp-caret-right u-textColorBrandLight">
                      &rsaquo;
                    </div>
                  ) : null}
                </div>
              </div>
            </>
          ) : error ? (
            <p className="u-marginTop3gu search-cleaned">
              Site search is temporarily unavailable. <br /> Please try again
              later. For immediate assistance,{' '}
              <a href="/customer-service/contact/" className="Link">
                contact us.
              </a>
            </p>
          ) : null}
        </section>
      </div>
    )
  }
}
