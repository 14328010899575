import React from 'react'
import ReactDOM from 'react-dom/client'
import IosBanner from './IosBanner'

export class IosBannerElement extends HTMLElement {
  connectedCallback() {
    const reactContainerId = 'ios-banner-element'
    const reactContainer = document.createElement('div')
    reactContainer.setAttribute('id', reactContainerId)
    this.appendChild(reactContainer)
    const show = this.getAttribute('data-show')
    const root = ReactDOM.createRoot(document.getElementById(reactContainerId))
    root.render(<IosBanner show={show} />)
  }
}
