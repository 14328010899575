import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import './ComplexHero.less'
import LoginForm from './LoginForm'
import { Spacer } from '@ampcore/design-system'

const ComplexHero = props => {
  const { content, testId } = props
  const { componentSpacing, featuredImage, backgroundFacet, backgroundImage } =
    content
  const headingRef = useRef(null)

  useEffect(() => {
    checkHeight()
  })

  function checkHeight() {
    if (headingRef.current.clientHeight <= 53) {
      headingRef.current.classList.add('Type-Display')
    }
    if (headingRef.current.clientHeight > 102) {
      headingRef.current.classList.remove('Type-Display')
    }
  }
  window.addEventListener('resize', checkHeight)

  const heroPattern = () => {
    if (backgroundFacet && featuredImage) {
      return 'facetCutout'
    }

    if (backgroundImage && !backgroundFacet) {
      return 'imageOverlay'
    }

    return 'illustration'
  }

  return (
    <Spacer spacing={componentSpacing}>
      <section
        className={`ComplexHero ComplexHero--${heroPattern()}`}
        data-analytics-container="Hero"
      >
        <div
          className={`u-flex u-sm-flexColReverse u-md-flexColReverse u-lg-paddingLeft3gu u-posRelative ${
            !backgroundFacet && !backgroundImage ? 'ComplexHero-border' : ''
          }`}
        >
          <div
            data-testid={testId ? `${testId}-background-content` : null}
            className={`ComplexHero-background u-posAbsolute ${
              !backgroundFacet && !backgroundImage ? 'ComplexHero-border' : ''
            }`}
            {...(backgroundImage && {
              style: { backgroundImage: `url(${backgroundImage})` }
            })}
            {...(backgroundFacet && {
              style: {
                backgroundImage: `url(${window.AMPCOMPATH}images/facets/complex-hero/complex-hero-facet-${backgroundFacet}.svg)`
              }
            })}
          ></div>
          <div className="ComplexHero-mobileOverlay u-lg-size6of12">
            <div
              className={`ComplexHero-content u-sm-marginHoriz4gu u-md-marginHoriz4gu u-paddingHoriz3gu u-marginLeft3gu u-sm-bgColorWhite u-md-bgColorWhite 
                ${
                  backgroundImage
                    ? 'u-bgColorWhite u-borderColorNeutralHero'
                    : ''
                }`}
              data-testid={testId ? `${testId}-content` : null}
            >
              <h1
                ref={headingRef}
                className={`u-textSecondary u-marginTop6gu u-textTight`}
                data-testid={testId ? `${testId}-heading` : null}
              >
                {content.heading}
              </h1>
              <div
                className="u-marginTop4gu"
                dangerouslySetInnerHTML={{ __html: content.content }}
                data-testid={testId ? `${testId}-body` : null}
              />
              <a
                className="Button Button--primary u-marginTop4gu u-sm-block u-marginBottom6gu"
                target={content.buttonTarget}
                href={content.buttonUrl}
                data-analytics={content.analytics}
                data-testid={testId ? `${testId}-link` : null}
              >
                {content.buttonText}
              </a>
            </div>
          </div>
          <div
            className={`ComplexHero-topWrapper u-flex u-flexJustifyCenter u-lg-size6of12`}
          >
            {featuredImage && (
              <div
                className={`ComplexHero-featuredImage`}
                style={{ backgroundImage: `url(${featuredImage})` }}
                data-testid={testId ? `${testId}-featured-image` : null}
              ></div>
            )}
          </div>
        </div>
      </section>
      {content.renderLogin && (
        <LoginForm
          bgColor={content.loginColor}
          isExpanded={content.expandLogin}
          data-testid={testId ? `${testId}-login-form` : null}
        />
      )}
    </Spacer>
  )
}
ComplexHero.propTypes = {
  content: PropTypes.shape({
    heading: PropTypes.string,
    buttonText: PropTypes.string,
    buttonUrl: PropTypes.string,
    buttonTarget: PropTypes.string,
    content: PropTypes.string,
    componentSpacing: PropTypes.oneOf(['bottom', 'top', 'full', 'nospace']),
    analytics: PropTypes.string,
    backgroundFacet: PropTypes.string,
    backgroundImage: PropTypes.string,
    featuredImage: PropTypes.string,
    loginColor: PropTypes.string,
    expandLogin: PropTypes.oneOf(['true', '']),
    renderLogin: PropTypes.oneOf(['true', ''])
  }),
  testId: PropTypes.string
}

export default ComplexHero
