import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export default class TabPanel extends Component {
  static propTypes = {
    isActive: PropTypes.bool.isRequired,
    panel: PropTypes.any.isRequired
  }
  constructor(props) {
    super(props)
    this.state = {}
  }

  // Render
  render() {
    let { panel, isActive } = this.props

    const activeClass = classNames({
      'tabbedContent-panel u-padding4gu u-sizeConstrained u-sm-block': true,
      'u-md-hidden u-lg-hidden': !isActive
    })

    return (
      <div
        className={activeClass}
        role="tabpanel"
        dangerouslySetInnerHTML={{ __html: panel }}
      ></div>
    )
  }
}
