import React, { useState, useEffect, useCallback } from 'react'
import xss from 'xss'
import PropTypes from 'prop-types'
import { Card } from '@ampcore/design-system'
import DropdownMenu from './DropdownMenu'

const CardList = props => {
  const {
    cardDescription,
    cardList,
    cardLimit,
    buttonLabel,
    dropdownBefore,
    dropdownAfter,
    allLabelValue,
    defaultCardCategory,
    componentSpacing
  } = props

  const determineUniqueCategories = useCallback(() => {
    const uniqueCategories = []
    cardList.forEach(card => {
      if (!card.cardCategory || uniqueCategories.includes(card.cardCategory)) {
        return
      }
      uniqueCategories.push(card.cardCategory)
    })
    return [allLabelValue, ...uniqueCategories.sort()]
  }, [allLabelValue, cardList])

  const getInitialCategory = () => {
    // edge case when we override all label value but set "All" as default
    if (defaultCardCategory === 'All' && allLabelValue) {
      return allLabelValue
    }
    return defaultCardCategory || allLabelValue
  }

  const [maxCards, setMaxCards] = useState(Number(cardLimit))
  const [categories, setCardCategories] = useState(() =>
    determineUniqueCategories()
  )
  const [currentCategory, setCurrentCategory] = useState(() =>
    getInitialCategory()
  )

  useEffect(() => {
    setCardCategories(determineUniqueCategories())
  }, [cardList, determineUniqueCategories])

  const handleButtonClick = () => {
    let newMaxCards = maxCards + Number(cardLimit)
    if (newMaxCards > cardList.length) {
      newMaxCards = cardList.length
    }
    setMaxCards(newMaxCards)
  }

  const handleCategoryChange = category => {
    if (category !== currentCategory) {
      setMaxCards(Number(cardLimit))
    }
    setCurrentCategory(category)
  }

  const cardsToDisplay = cardList.filter(currCard => {
    if (currentCategory === allLabelValue) {
      return true
    }
    return currCard.cardCategory === currentCategory
  })

  return (
    <section
      className={`card-list-component ${componentSpacing}`}
      limit={cardLimit}
    >
      <div
        className="Grid u-sizeConstrained u-sm-flexCol u-sm-flexJustifyCenter"
        data-testid={props.testId || null}
      >
        {cardDescription && (
          <div
            className="card-description Content u-marginBottom4gu"
            dangerouslySetInnerHTML={{ __html: xss(cardDescription) }}
          />
        )}
        {categories.length > 1 && (
          <div className="u-flex u-lg-flexJustifyBetween u-lg-flexRow u-flexCol u-sizeFull u-flexAlignItemsBaseline u-posRelative u-marginBottom4gu">
            <DropdownMenu
              items={categories}
              onChangeHandler={handleCategoryChange}
              summaryBeforeText={dropdownBefore}
              summaryAfterText={dropdownAfter}
              currentItem={currentCategory}
            />
            <p className="u-sm-paddingTop2gu u-md-paddingTop2gu">{`${
              cardsToDisplay.length
            } Result${cardsToDisplay.length !== 1 ? 's' : ''}`}</p>
          </div>
        )}
        <div className="card-list-wrapper u-sm-flex u-sm-flexCol u-marginBottom4gu">
          {cardsToDisplay.slice(0, maxCards).map((card, index) => (
            <Card key={index} card={card} />
          ))}
        </div>
        {maxCards < cardsToDisplay.length && (
          <div className="load-more u-flex u-flexJustifyCenter">
            <button
              className="Button Button--secondary Button--secondary--action-blue"
              onClick={handleButtonClick}
            >
              {buttonLabel}
            </button>
          </div>
        )}
      </div>
    </section>
  )
}
CardList.propTypes = {
  cardDescription: PropTypes.string,
  cardList: PropTypes.array,
  cardLimit: PropTypes.string,
  buttonLabel: PropTypes.string,
  dropdownBefore: PropTypes.string,
  dropdownAfter: PropTypes.string,
  allLabelValue: PropTypes.string,
  defaultCardCategory: PropTypes.string,
  componentSpacing: PropTypes.string,
  testId: PropTypes.string || null
}
CardList.defaultProps = {
  cardDescription: '',
  cardList: [],
  cardLimit: '6',
  buttonLabel: 'Load More',
  dropdownBefore: 'View',
  dropdownAfter: 'Articles',
  allLabelValue: 'All',
  defaultCardCategory: '',
  componentSpacing: '',
  testId: null
}
export default CardList
