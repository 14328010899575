import React from 'react'

const Icon = props => {
  return (
    <i className={'u-paddingRight1gu'}>
      <button className={`Icon ${props.size}`}>{props.children}</button>
      <span className="u-hiddenVisually">{props.description}</span>
    </i>
  )
}

export default Icon
