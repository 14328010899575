import React from 'react'
import { SpacerSpacing } from './SpacerSpacing.type'

export type SpacerProps = {
  spacing?: SpacerSpacing
  children?: React.ReactNode
  testId?: string
}

export const Spacer = (props: SpacerProps) => {
  const { spacing, children, testId } = props

  const classList: string[] = []

  switch (spacing) {
    case 'bottom':
      classList.push('u-sm-marginBottom7gu')
      classList.push('u-marginBottom10gu')
      break
    case 'top':
      classList.push('u-sm-marginTop7gu')
      classList.push('u-marginTop10gu')
      break
    case 'full':
      classList.push('u-sm-marginVert7gu')
      classList.push('u-marginVert10gu')
      break
    case 'nospace':
      break
  }

  if (classList.length === 0) {
    return children
  }

  return (
    <div
      className={classList.join(' ')}
      data-testid={testId ? `${testId}-wrapper` : null}
    >
      {children}
    </div>
  )
}

export default Spacer
