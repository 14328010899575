/* eslint-disable */
import React, { useState } from 'react'
import { string, node } from 'prop-types'
import './index.css'

const AMPLOGO = window.AMPMSSITEPATH + 'images/shared/amp-site-logo-sm.png'
const AMPLOGOSM =
  window.AMPMSSITEPATH + 'images/shared/SOLID_WHITE_LOGO_SMALL.png'

/*
 * <MicrositeHeader />
 * Renders the site header
 */

export const MicrositeHeader = props => {
  let largeSizeImage = AMPLOGO
  if (props && props.imageLarge) {
    largeSizeImage = props.imageLarge
  }

  let smallSizeImage = AMPLOGOSM
  if (props && props.imageSmall) {
    smallSizeImage = props.imageSmall
  }

  const [popover, isPopoverVisible] = useState(false)

  return (
    <>
      <div className="PageHeader amp-header u-bgColorWhite">
        <div className="u-sizeConstrained">
          <div className="u-flex u-flexRow u-flexJustifyBetween">
            <button className="u-md-hidden u-lg-hidden amp-hamburger">
              <div className="u-flex u-flexCol u-flexJustifyCenter u-flexAlignItemsCenter">
                <div
                  className="amp-hamburger-icon"
                  onClick={() => isPopoverVisible(!popover)}
                >
                  <div aria-hidden="true" className="u-textColorActionBlue">
                    <div className="amp-hamburger-icon-dash u-bgColorActionBlue u-marginTop1gu u-marginBottom1gu">
                      &nbsp;
                    </div>
                    <div className="amp-hamburger-icon-dash u-bgColorActionBlue u-marginTop1gu u-marginBottom1gu">
                      &nbsp;
                    </div>
                    <div className="amp-hamburger-icon-dash u-bgColorActionBlue u-marginTop1gu u-marginBottom1gu">
                      &nbsp;
                    </div>
                  </div>
                  <span className="u-hiddenVisually">Menu</span>
                </div>
                <div className="u-textColorActionBlue" data-qa="HamburgerMenu">
                  Menu
                </div>
              </div>
            </button>
            <div className="u-flex u-sm-flexAlignSelfCenter amp-siteLogo">
              <a href="/" data-analytics="header-amp-logo">
                <img
                  src={largeSizeImage}
                  alt="Ameriprise Financial"
                  className="u-sm-hidden u-md-hidden"
                ></img>
                <img
                  src={smallSizeImage}
                  alt="Ameriprise Financial"
                  className="u-lg-hidden"
                ></img>
              </a>
            </div>
            <div className="PageHeader-buttonWrap u-sm-hidden u-md-flexRow u-lg-flexRow u-sm-marginTop4gu">
              <a
                href="/privacy-security-fraud/online-security-guarantee"
                className=" Link u-textPrimary u-linkClean"
              >
                <i className="Icon icon-shield" aria-hidden /> Online security
                guarantee
              </a>
              <span className="u-textPrimary u-marginHoriz2gu">|</span>
              <a
                href="/customer-service"
                className=" Link u-textPrimary u-linkClean"
              >
                Customer service
              </a>
            </div>
          </div>
        </div>
      </div>
      {popover && (
        <div className="amp-nav u-decoBorderLight u-decoBorderResetTop">
          <div className="u-sizeConstrained amp-nav-inner u-flex Popover amp-nav-level-one is-visible">
            <nav className="u-flex u-flexAlignItemsStretch amp-nav-level-one">
              <ul
                className="List List--selectable amp-mainNav u-flexJustifyStart u-decoBorderResetBottom"
                aria-label="menu"
                data-qa="MainNavLevelOne"
              >
                <li className="List-item amp-mainNav-item amp-mainNav-item u-lg-hidden">
                  <a
                    className="amp-mainNav-link Link u-textPrimary u-linkClean"
                    href="/privacy-security-fraud/online-security-guarantee"
                    data-analytics="Online security guarantee"
                  >
                    Online security guarantee
                  </a>
                </li>
                <li className="List-item amp-mainNav-item amp-mainNav-item u-lg-hidden">
                  <a
                    className="amp-mainNav-link Link u-textPrimary u-linkClean"
                    href="/customer-service"
                    data-analytics="Customer Service"
                  >
                    Customer Service
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      )}
    </>
  )
}

MicrositeHeader.propTypes = {
  className: string,
  children: node,
  imageLarge: string,
  imageSmall: string,
  component: string
}

MicrositeHeader.defaultProps = {
  children: null,
  className: '',
  imageLarge: '',
  imageSmall: '',
  component: ''
}
