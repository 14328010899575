import { createElement } from '@cwp/utils'

export function htmlToPdf(html = '') {
  if (!window.HTML2PDF_CLIENT_KEY) {
    throw new Error('HTML2PDF_CLIENT_KEY undefined')
  }

  const filename = `IRA-ROLLOVER-EVALUATOR-${new Date().getTime()}.pdf`
  const defaultSettings = {
    apikey: window.HTML2PDF_CLIENT_KEY,
    filename: filename,
    unit: 'in',
    left: 0.25,
    right: 0.25,
    top: 0.25,
    bottom: 0.25,
    pagesize: 'Letter',
    orientation: 'portrait',
    cmt: 'print',
    html: ''
  }

  const form = createElement('form', {
    action: 'https://ampf.htm2pdf.co.uk/urltopdf',
    method: 'POST',
    style: 'display: none',
    target: '_self'
  })

  const data = { ...defaultSettings, html: html }
  Object.keys(data).forEach(key => {
    const input = createElement('input', {
      name: key,
      type: 'hidden',
      value: data[key]
    })

    form.appendChild(input)
  })

  document.body.appendChild(form)
  form.submit()
  form.remove()
}
