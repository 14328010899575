import React, { useEffect, useState, useRef } from 'react'
import { CardContentProps, CardContent } from './CardContent'
import { FeatureFlagProps } from './FeatureFlag'
import './style.module.scss'

export type CardRecord = {
  cardCategory?: string
  cardContent?: CardContentProps
  cardArticle?: CardContentProps
  collapse?: boolean
  featureFlag?: FeatureFlagProps
}

export type CardProps = {
  card: CardRecord
  onFocus?: React.FocusEventHandler<HTMLAnchorElement>
}

export const Card = (props: CardProps) => {
  const { card, onFocus } = props
  const { cardCategory, cardArticle, featureFlag, cardContent } = card
  const [isMoving, setIsMoving] = useState(false)
  const linkRef = useRef<HTMLAnchorElement>(null)
  const [cardValues, setCardValues] = useState<CardContentProps>({})

  useEffect(() => {
    if (cardArticle) {
      setCardValues(cardArticle)
      return
    }

    setCardValues(cardContent ?? {})
  }, [cardArticle, cardContent])

  const handleTouchStart = () => {
    setIsMoving(false)
  }
  const handleTouchMove = () => {
    setIsMoving(true)
  }
  const handleOnClick = () => {
    if (linkRef.current) {
      linkRef.current.click()
    }
  }
  const handleTouchEnd = () => {
    if (!isMoving) {
      handleOnClick()
    }
    setIsMoving(false)
  }
  const getCardContent = () => {
    const {
      cardDescription,
      heading,
      superHeadline,
      author,
      readTime,
      date,
      cardImage
    } = cardValues

    if (
      !featureFlag &&
      !cardDescription &&
      !heading &&
      !superHeadline &&
      !author &&
      !readTime &&
      !date &&
      !cardImage
    ) {
      return null
    }

    return (
      <div
        className={`Card Card--redesign Card--withHeader u-flex u-flexCol u-paddingBottom4gu u-posRelative u-bgColorWhite u-sizeFull`}
        card-category={cardCategory}
      >
        <CardContent
          featureFlag={featureFlag}
          cardDescription={cardDescription}
          heading={heading}
          superHeadline={superHeadline}
          author={author}
          readTime={readTime}
          date={date}
          cardImage={cardImage}
        />
      </div>
    )
  }

  if (cardValues.url) {
    return (
      <a
        href={cardValues.url}
        className="u-linkClean Card-Border"
        data-analytics={cardValues.dataAnalytics}
        onFocus={onFocus}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        ref={linkRef}
        onMouseDown={e => {
          e.stopPropagation()
          e.preventDefault()
        }}
      >
        {getCardContent()}
      </a>
    )
  }

  return getCardContent()
}
