import React, { Component } from 'react'

/*
 * Helper methods
 */
import { setInnerHtml } from './utils'

/*
 *  <SuccessMessage
 *  message='Thank you, we have received your request' //Success message to be passed on to display
 *  />
 */

/*
 * Success Message Component
 */
// Componet should be in class when we are using Refs
class SuccessMessage extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props)

    this.dataQaSuccess = 'Message--success'
    this.successMessageRef = React.createRef()
  }
  componentDidMount() {
    if (this.successMessageRef) {
      const scrollToY = this.successMessageRef.current.offsetTop
      this.successMessageRef.current.focus()
      window.scrollTo(0, scrollToY)
    }
  }
  render() {
    const { thankYou, message, dataAnalytics } = this.props

    const dataAnalyticsMsg = dataAnalytics || this.dataQaSuccess

    return (
      <div className="u-sizeConstrained" role="alert">
        <div className="Notification Notification--withIcon Notification--alert Notification--confirm u-marginBottom5gu u-padding5gu u-bgColorLight">
          <h3
            className="Notification-content js-formNotification"
            data-qa={this.dataQaSuccess}
            data-analytics={dataAnalyticsMsg}
            data-icon={'\u2713'}
          >
            {thankYou}
          </h3>
          <p
            className="u-padding3gu"
            data-qa={this.dataQaSuccess}
            dangerouslySetInnerHTML={setInnerHtml(message)}
            ref={this.successMessageRef}
          />
        </div>
      </div>
    )
  }
}

export default SuccessMessage
