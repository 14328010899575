import React, { useEffect, useState } from 'react'
import xss from 'xss'
import { FeatureFlag, FeatureFlagProps } from './FeatureFlag'

export type CardContentProps = {
  cardImage?: string
  heading?: string
  superHeadline?: string
  cardDescription?: string
  readTime?: string
  author?: string
  date?: string
  featureFlag?: FeatureFlagProps
  url?: string
  dataAnalytics?: string
}

export const CardContent = (props: CardContentProps) => {
  const {
    cardDescription,
    cardImage,
    heading,
    superHeadline,
    readTime,
    featureFlag,
    author,
    date
  } = props

  const [imagePath, setImagePath] = useState('')

  useEffect(() => {
    if (cardImage) {
      setImagePath(cardImage)
      return
    }
    setImagePath(`${window.AMPCOMPATH}images/card-default-image.png`)
  }, [cardImage])

  const getCardDetails = () => {
    if (!author && !readTime && !date) {
      return null
    }
    const details = [author, date, readTime].filter(item => !!item)
    return (
      <div className="card-bottom u-sizeFull u-paddingHoriz4gu">
        <p className="u-textColorLight u-textSizeMsrm1">
          {details.map((detail, index) => (
            <React.Fragment key={index}>
              <span>{detail}</span>
              {index < details?.length - 1 && (
                <span className="u-marginHoriz1gu">&bull;</span>
              )}
            </React.Fragment>
          ))}
        </p>
      </div>
    )
  }

  return (
    <>
      {featureFlag && (
        <FeatureFlag
          backgroundColor={featureFlag.backgroundColor}
          label={featureFlag.label}
        />
      )}
      <div className="Card-header Card-headerBackgroundImage card-image">
        <img src={imagePath} alt="" />
      </div>
      <div className="Card-body card-content u-paddingHoriz4gu">
        {superHeadline && (
          <h5 className="u-textColorLight u-textSizeMsrm1 u-textUppercase">
            {superHeadline}
          </h5>
        )}
        <h4 className="card-heading u-paddingVert2gu u-textColorNeutralGrey">
          <strong>{heading}</strong>
        </h4>
        {cardDescription && (
          <div
            className="card-description"
            dangerouslySetInnerHTML={{ __html: xss(cardDescription) }}
          />
        )}
      </div>
      {getCardDetails()}
    </>
  )
}
