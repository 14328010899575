import React from 'react'
import xss from 'xss'
import { Spacer } from '../Spacer/Spacer'
import { SpacerSpacing } from '../Spacer/SpacerSpacing.type'
import { HeroSectionFrontColor } from './HeroSectionFrontColor.type'
import './style.module.scss'

export type HeroSectionFrontProps = {
  content: {
    heading?: string
    body?: string
    image?: string
    imageMobile?: string
    textAlignment?: string
    facetColor?: HeroSectionFrontColor
    hideMobileImage?: string
    componentSpacing?: SpacerSpacing
  }
  testId?: string
}

export const HeroSectionFront = (props: HeroSectionFrontProps) => {
  const { content, testId } = props
  const {
    heading,
    body,
    image,
    imageMobile,
    textAlignment,
    facetColor,
    hideMobileImage,
    componentSpacing
  } = content

  const showMobileImage = hideMobileImage !== 'true'
  const largeFacet = `${window.AMPCOMPATH}images/facets/hero-facets-${
    facetColor || 'midnight-blue'
  }.svg`
  const mobileFacet = `${window.AMPCOMPATH}images/facets/hero-facets-mobile-${
    facetColor || 'midnight-blue'
  }.svg`

  return (
    <Spacer spacing={componentSpacing}>
      <section className="HeroSectionFront" data-analytics-container="Hero">
        <img
          className="HeroSectionFront-facet u-sm-hidden"
          src={largeFacet}
          alt=""
          data-testid={testId ? `${testId}-facet` : null}
        />
        <img
          className="HeroSectionFront-facet u-md-hidden u-lg-hidden"
          src={mobileFacet}
          alt=""
          data-testid={testId ? `${testId}-facet-mobile` : null}
        />

        <div className="HeroSectionFront-content">
          <div className="HeroSectionFront-heading u-sizeConstrained">
            {heading && (
              <h1
                className={`Type-Display u-paddingTop10gu ${textAlignment}`}
                dangerouslySetInnerHTML={{ __html: xss(heading) }}
                data-testid={testId ? `${testId}-heading` : null}
              ></h1>
            )}
            {body && (
              <div
                className={`Content Content--large u-paddingTop2gu ${textAlignment}`}
                dangerouslySetInnerHTML={{ __html: xss(body) }}
                data-testid={testId ? `${testId}-body` : null}
              ></div>
            )}
          </div>
          <div className="u-paddingBottom10gu u-sm-paddingHoriz3gu u-paddingTop10gu u-posRelative">
            {image && (
              <img
                className="HeroSectionFront-featuredImage u-sm-hidden u-block u-md-sizeFull u-sizeConstrained"
                src={image}
                alt=""
                data-testid={testId ? `${testId}-image` : null}
              />
            )}
            {showMobileImage && (
              <img
                className="HeroSectionFront-featuredImage u-md-hidden u-lg-hidden u-block u-sizeConstrained"
                src={imageMobile}
                alt=""
                data-testid={testId ? `${testId}-image-mobile` : null}
              />
            )}
          </div>
        </div>
      </section>
    </Spacer>
  )
}
