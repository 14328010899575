import React, { Component } from 'react'
import TabList from './TabList.jsx'
import './TabbedContent.less'

export default class TabbedContent extends Component {
  render() {
    return (
      <div className="tabbedContent u-adel-reset">
        <TabList
          tabs={this.props.content.labels.child}
          panels={this.props.content.panels}
        />
      </div>
    )
  }
}
