import React from 'react'
import PropTypes from 'prop-types'
import './TwoColumnBanner.less'
import { Spacer, BrandIcon } from '@ampcore/design-system'
import xss from 'xss'

const renderButton = (
  btnText,
  btnUrl,
  btnTarget,
  btnAnalytics,
  btnType,
  btnColor
) => {
  if (!btnUrl) return null
  return (
    <a
      className={`Button Button--${btnType} ${
        btnColor === 'white' ? 'Button--reversed' : ''
      } ${
        btnType === 'primary'
          ? 'u-marginRight2gu u-sm-marginRight0 u-sm-marginBottom2gu'
          : ''
      }`}
      href={btnUrl}
      target={btnTarget}
      data-analytics={btnAnalytics}
    >
      {btnText}
    </a>
  )
}

const renderColumn = columnProps => {
  const {
    testId,
    icon,
    iconColor,
    eyebrowImage,
    eyebrowImageAlt,
    eyebrowText,
    columnContent,
    hasImage,
    primaryBtn,
    tertiaryBtn,
    columnClasses
  } = columnProps

  return (
    <div
      className={`TwoColumnBanner-column u-lg-size6of12 u-sm-sizeFull u-paddingVert0 u-paddingHoriz0 ${columnClasses}`}
      data-testid={testId ? `${testId}-columnSection` : null}
    >
      <div className={`${hasImage ? 'TwoColumnBanner-fullHeight' : ''}`}>
        {icon && (
          <div className="u-paddingBottom2gu">
            <BrandIcon icon={icon} className={iconColor} />
          </div>
        )}
        {eyebrowImage && (
          <div className="u-paddingBottom2gu">
            <img
              src={eyebrowImage}
              alt={eyebrowImageAlt}
              className="TwoColumnBanner-eyebrowImage"
            />
          </div>
        )}
        {eyebrowText && (
          <p className="Type-5 u-textEyebrow u-paddingVert2gu">{eyebrowText}</p>
        )}
        <div
          className={`TwoColumnBanner-innerContent ${
            hasImage ? 'TwoColumnBanner-fullHeight' : ''
          }`}
          dangerouslySetInnerHTML={{ __html: xss(columnContent) }}
        />
        {(primaryBtn || tertiaryBtn) && (
          <div className="u-flex u-sm-flexCol u-paddingTop6gu">
            {primaryBtn}
            {tertiaryBtn}
          </div>
        )}
      </div>
    </div>
  )
}

const getColumnClasses = (
  column,
  leftContentHasImage,
  rightContentHasImage
) => {
  let defaultClasses = `u-sm-paddingHoriz3gu u-md-paddingHoriz4gu `
  let contentHasImage = `u-sm-flexOrderFirst u-md-flexOrderFirst u-sm-paddingBottom8gu u-md-paddingBottom8gu u-flexAlignContentCenter`
  let contentHasNoImage = `u-paddingRight8gu u-sm-paddingBottom8gu u-md-paddingBottom8gu u-flexAlignContentStart`

  if (column === 'left') {
    if (leftContentHasImage) return defaultClasses + contentHasImage
    if (!leftContentHasImage && rightContentHasImage)
      return defaultClasses + 'u-flexAlignContentCenter u-paddingRight8gu'
    if (!leftContentHasImage && !rightContentHasImage)
      return defaultClasses + contentHasNoImage
  }
  if (column === 'right') {
    if (rightContentHasImage) return defaultClasses + contentHasImage
    if (!rightContentHasImage && leftContentHasImage)
      return defaultClasses + 'u-flexAlignContentCenter u-paddingLeft8gu'
  }
  return defaultClasses.trim()
}

const isBgDark = color => {
  return ['purple', 'midnight-blue'].includes(color)
}

const getColorClasses = (bgColor, textColor) => {
  const colorClasses = {
    stone: 'u-bgColorStone',
    'purple-light': 'u-bgColorPurpleLight',
    'mint-light': 'u-bgColorMintLight',
    'lime-light': 'u-bgColorLimeLight',
    'midnight-blue': 'u-bgColorMidnightBlue',
    purple: 'u-bgColorPurple',
    transparent: 'u-bgColorTransparent',
    white: 'u-bgColorWhite'
  }
  const bgClass = colorClasses[bgColor] || colorClasses['white']
  const isDark = isBgDark(bgColor)
  const contrastClass =
    textColor === 'white' || isDark ? 'Content--reversed' : ''
  return `${bgClass} ${contrastClass}`
}

const TwoColumnBanner = ({ content, testId }) => {
  const {
    backgroundImage,
    backgroundColor,
    textColor,
    componentSpacing,
    leftIcon,
    leftIconColor,
    leftEyebrowImage,
    leftEyebrowImageAlt,
    leftEyebrowText,
    leftColumnContent,
    leftPrimaryBtnText,
    leftPrimaryBtnUrl,
    leftPrimaryBtnTarget,
    leftPrimaryBtnAnalytics,
    leftTertiaryBtnText,
    leftTertiaryBtnUrl,
    leftTertiaryBtnTarget,
    leftTertiaryBtnAnalytics,
    rightIcon,
    rightIconColor,
    rightEyebrowImage,
    rightEyebrowText,
    rightEyebrowImageAlt,
    rightColumnContent,
    rightPrimaryBtnText,
    rightPrimaryBtnUrl,
    rightPrimaryBtnTarget,
    rightPrimaryBtnAnalytics,
    rightTertiaryBtnText,
    rightTertiaryBtnUrl,
    rightTertiaryBtnTarget,
    rightTertiaryBtnAnalytics,
    btnColor
  } = content

  const leftContentHasImage = leftColumnContent?.includes('<img')
  const rightContentHasImage = rightColumnContent?.includes('<img')

  const leftPrimaryBtn = renderButton(
    leftPrimaryBtnText,
    leftPrimaryBtnUrl,
    leftPrimaryBtnTarget,
    leftPrimaryBtnAnalytics,
    'primary',
    btnColor
  )
  const leftTertiaryBtn = renderButton(
    leftTertiaryBtnText,
    leftTertiaryBtnUrl,
    leftTertiaryBtnTarget,
    leftTertiaryBtnAnalytics,
    'tertiary',
    btnColor
  )
  const rightPrimaryBtn = renderButton(
    rightPrimaryBtnText,
    rightPrimaryBtnUrl,
    rightPrimaryBtnTarget,
    rightPrimaryBtnAnalytics,
    'primary',
    btnColor
  )
  const rightTertiaryBtn = renderButton(
    rightTertiaryBtnText,
    rightTertiaryBtnUrl,
    rightTertiaryBtnTarget,
    rightTertiaryBtnAnalytics,
    'tertiary',
    btnColor
  )

  return (
    <Spacer spacing={componentSpacing}>
      <section
        className={`TwoColumnBanner u-flex u-flexAlignItemsStretch u-flexAlignItemsCenter ${getColorClasses(
          backgroundColor,
          textColor
        )}`}
        style={{
          backgroundImage: backgroundImage ? `url(${backgroundImage})` : ''
        }}
        data-testid={testId ? `${testId}-backgroundImage` : null}
      >
        <div className="Content Grid u-paddingVert8gu u-sm-paddingVert6gu u-md-paddingVert6gu u-sizeConstrained">
          {renderColumn({
            testId: testId ? `${testId}-left` : null,
            icon: leftIcon,
            iconColor: leftIconColor,
            eyebrowImage: leftEyebrowImage,
            eyebrowImageAlt: leftEyebrowImageAlt,
            eyebrowText: leftEyebrowText,
            columnContent: leftColumnContent,
            hasImage: leftContentHasImage,
            primaryBtn: leftPrimaryBtn,
            tertiaryBtn: leftTertiaryBtn,
            columnClasses: getColumnClasses(
              'left',
              leftContentHasImage,
              rightContentHasImage
            )
          })}
          {renderColumn({
            testId: testId ? `${testId}-right` : null,
            icon: rightIcon,
            iconColor: rightIconColor,
            eyebrowImage: rightEyebrowImage,
            eyebrowImageAlt: rightEyebrowImageAlt,
            eyebrowText: rightEyebrowText,
            columnContent: rightColumnContent,
            hasImage: rightContentHasImage,
            primaryBtn: rightPrimaryBtn,
            tertiaryBtn: rightTertiaryBtn,
            columnClasses: getColumnClasses(
              'right',
              leftContentHasImage,
              rightContentHasImage
            )
          })}
        </div>
      </section>
    </Spacer>
  )
}

TwoColumnBanner.propTypes = {
  content: PropTypes.shape({
    backgroundImage: PropTypes.string,
    backgroundColor: PropTypes.string,
    textColor: PropTypes.string,
    componentSpacing: PropTypes.oneOf(['bottom', 'top', 'full', 'nospace']),
    leftEyebrowText: PropTypes.string,
    leftEyebrowImageAlt: PropTypes.string,
    leftIcon: PropTypes.string,
    leftIconColor: PropTypes.string,
    leftColumnContent: PropTypes.string,
    leftPrimaryBtnText: PropTypes.string,
    leftPrimaryBtnUrl: PropTypes.string,
    leftPrimaryBtnTarget: PropTypes.string,
    leftPrimaryBtnAnalytics: PropTypes.string,
    leftTertiaryBtnText: PropTypes.string,
    leftTertiaryBtnUrl: PropTypes.string,
    leftTertiaryBtnTarget: PropTypes.string,
    leftTertiaryBtnAnalytics: PropTypes.string,
    rightEyebrowText: PropTypes.string,
    rightEyebrowImageAlt: PropTypes.string,
    rightIcon: PropTypes.string,
    rightIconColor: PropTypes.string,
    rightColumnContent: PropTypes.string,
    rightPrimaryBtnText: PropTypes.string,
    rightPrimaryBtnUrl: PropTypes.string,
    rightPrimaryBtnTarget: PropTypes.string,
    rightPrimaryBtnAnalytics: PropTypes.string,
    rightTertiaryBtnText: PropTypes.string,
    rightTertiaryBtnUrl: PropTypes.string,
    rightTertiaryBtnTarget: PropTypes.string,
    rightTertiaryBtnAnalytics: PropTypes.string,
    btnColor: PropTypes.string
  }),
  testId: PropTypes.string
}
export default TwoColumnBanner
