import { handleEvent } from '@cwp/utils'
import { empty } from '../common/dom'
import { nextTick } from '../common/sleep'

const { assign } = Object

/**
 * Component
 */
export default class Component {
  /**
   * @constructor
   */
  constructor(element, props = {}) {
    if (!element) {
      throw new Error('Component initialized without an element')
    }

    this.element = element
    this.props = props

    // Next tick
    nextTick(() => this.setState(this.state))
  }

  /**
   * @private _render
   */
  _render() {
    if (this.render) {
      let rendered = this.render()

      // Detect jQuery
      if (rendered.get) {
        rendered = rendered.get(0)
      }

      empty(this.element).append(rendered)
    }
  }

  /**
   * setState
   */
  setState(diff = {}) {
    this.state = assign({}, this.state, diff)
    this._render()
  }

  /**
   * on
   */
  on(eventName, selector, handler) {
    if (this.element) {
      return handleEvent(this.element, eventName, selector, handler)
    } else {
      throw new Error('unable to bind events to this component')
    }
  }
}
