const setupNavigationOptions = navOptions => {
  let options = new Map(Object.entries(navOptions))
  const setOptions = (name = '', fn = () => {}, callback = () => {}) => {
    let active
    if (options.has(name)) {
      active = options.get(name)
      options.set(name, {
        active: active,
        fn: fn
      })
      if (active) {
        callback()
      }
    }
  }

  const getOptions = () => {
    return options
  }

  const isActiveOption = (name = '') => {
    return options.has(name) && options.get(name).active
  }

  return {
    getOptions: getOptions,
    setOptions: setOptions,
    isActiveOption: isActiveOption
  }
}

export { setupNavigationOptions }
