import React from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import classes from './Classes'
import './SortedTab.less'
import Chevron from './Chevron'
import CardList from './CardList'

/**
 * this.props.content.cardList
 * @param Array cardList is an array of card objects
 */

export default class SortedTab extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      cards: {},
      categories: [],
      selectedTab: 0
    }
  }

  componentDidMount() {
    const { cardList } = this.props.content
    const cards = cardList.reduce(
      (accumulator, card) => {
        if (card.cardCategory === '' || card.cardCategory === 'All') {
          card.cardCategory = 'All Articles'
        }
        if (!accumulator[card.cardCategory]) {
          accumulator[card.cardCategory] = []
        }
        accumulator[card.cardCategory].push(card)
        if (card.cardCategory !== 'All Articles') {
          accumulator['All Articles'].push(card)
        }
        return accumulator
      },
      { 'All Articles': [] }
    )
    this.setState({ cards: cards, categories: Object.keys(cards) })
  }

  handleChevronClick() {
    // move the scroll bar on chevron click
    const tabHorLoc = {}
    const tabs = document.querySelectorAll('div[data-is="SortedTab"] .Tabs li')
    let distance = 0
    tabs.forEach(tab => {
      tabHorLoc[tab.id] = distance
      distance += tab.scrollWidth
    })
    const selectedTab = document.querySelector(
      'div[data-is="SortedTab"] .Tabs li[aria-selected="true"]'
    )
    const tabContainer = document.querySelector(
      'div[data-is="SortedTab"] .Tabs'
    )
    tabContainer.scrollLeft = tabHorLoc[selectedTab.id]
  }

  makeTabs() {
    return this.state.categories.map(category => {
      return (
        <Tab key={category} className={classes.tab}>
          {category}
        </Tab>
      )
    })
  }

  makePanels() {
    return this.state.categories.map(category => {
      return (
        <TabPanel key={category} className={classes.tabPanel}>
          <CardList
            cardList={this.state.cards[category]}
            category={category}
            cardLimit={this.props.content.cardLimit}
          />
        </TabPanel>
      )
    })
  }

  render() {
    return (
      <Tabs
        className={classes.tabComponent}
        selectedIndex={this.state.selectedTab}
        onSelect={index => this.setState({ selectedTab: index })}
      >
        <TabList className={classes.tabContainer}>
          <Chevron
            id={'chevron-left'}
            description={'Chevron left'}
            handleChevronClick={() => {
              this.setState(
                prev => ({ selectedTab: prev.selectedTab - 1 }),
                () => this.handleChevronClick()
              )
            }}
            hidden={this.state.selectedTab === 0}
          >
            &#xe314;
          </Chevron>
          <div className={classes.tabsHoriz}>{this.makeTabs()}</div>
          <Chevron
            id={'chevron-right'}
            description={'Chevron right'}
            handleChevronClick={() => {
              this.setState(
                prev => ({ selectedTab: prev.selectedTab + 1 }),
                () => this.handleChevronClick()
              )
            }}
            hidden={this.state.selectedTab === this.state.categories.length - 1}
          >
            &#x25b9;
          </Chevron>
        </TabList>
        {this.makePanels()}
      </Tabs>
    )
  }
}
