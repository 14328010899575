import React, { useState, useRef } from 'react'
import { getAdvisorSearchUrl } from '../utils.js'
import {
  isGeoSupported,
  findGeoLocation,
  getZipCodeFromAddress
} from '../../../../services/geoCoordinatesLookup'
import { BrandIcon } from '@ampcore/design-system'
import PropTypes from 'prop-types'

function ProspectImage({ image }) {
  return (
    <img
      className="Advisor-image u-maxWidth100"
      alt={image.alt}
      title={image.title}
      src={image.src}
    />
  )
}

const Prospect = props => {
  const { content, testId } = props
  const { prospectLabels } = content
  const zipRef = useRef()
  const [state, setState] = useState({
    value: props.zip,
    geoPermission: props.locPermission,
    buttonTriggered: false,
    loader: false,
    isInvalidZip: false
  })
  const { geoPermission, buttonTriggered, loader, isInvalidZip, value } = state

  function submitUpdateZip(event) {
    const zip = zipRef.current.value

    // TODO remove condition once old template depreciated
    if (content.template !== 'nextgen2024') {
      if (zip.length > 0) {
        window.open(getAdvisorSearchUrl({ zip }), '_blank')
      }
      return
    }

    if (zip && zip !== '' && zip.length === 5) {
      window.open(getAdvisorSearchUrl({ zip }), '_blank')
    } else {
      setState({ isInvalidZip: true })
      zipRef.current.setAttribute(
        'aria-label',
        'Enter a valid 5-digit ZIP code'
      )
      zipRef.current.focus()
    }
  }

  function geoLocateUser(event) {
    setState({ loader: true })
    if (isGeoSupported()) {
      findGeoLocation((returnObj, message) => {
        if (returnObj && message && message === 'success') {
          const retrievedZip = getZipCodeFromAddress(returnObj)
          retrievedZip
            .then(response => {
              if (response.status && response.zip && response.zip.long_name) {
                const zipCode = response.zip.long_name
                props.updateParentState({
                  zip: zipCode,
                  locPermission: true,
                  message: 'success'
                })
                setState({
                  buttonTriggered: true,
                  geoPermission: true,
                  value: zipCode,
                  loader: false
                })
              }
            })
            .catch(() => {
              setState({
                buttonTriggered: true,
                geoPermission: true,
                loader: false
              })
            })
        } else {
          props.updateParentState({
            locPermission: false,
            message: 'user denied the access'
          })
          setState({
            buttonTriggered: true,
            geoPermission: false,
            loader: false
          })
          if (zipRef.current) zipRef.current.focus()
        }
      })
    }
  }

  const isSkinny = props.content.prospectTemplate === 'Skinny DAC'
  const media = (!isSkinny && content.image) ? <ProspectImage image={content.image} /> : ''
  const formClasses = isSkinny
    ? 'AdvisorProspect-searchForm u-marginBottom2gu u-marginRight4gu u-marginLeft4gu u-paddingHoriz4gu u-paddingVert2gu u-sm-paddingBottom4gu u-sm-paddingTop0 u-sm-paddingHoriz0 u-sm-marginLeft0 u-sm-marginRight0'
    : 'AdvisorProspect-searchForm AdvisorProspect-framedSearchForm u-marginBottom6gu u-decoRoundedCorners u-sm-decoBorderReset u-padding4gu u-sm-paddingVert0 u-sm-paddingHoriz0'

  const newFormClasses = isSkinny
    ? 'AdvisorProspect-searchForm u-marginBottom2gu u-marginRight4gu u-marginLeft4gu u-paddingHoriz4gu u-paddingVert2gu u-sm-paddingBottom4gu u-bgColorWhite'
    : 'AdvisorProspect-searchForm AdvisorProspect-framedSearchForm u-sm-decoBorderReset u-padding4gu u-bgColorWhite'

  if (content.template === 'nextgen2024') {
    return (
      <div
        className={
          'ProspectRedesign Advisor--prospectView u-posRelative u-bgColorMidnightBlue' +
          (isSkinny
            ? ' u-lg-size8of12 u-lg-before2of12 u-md-size8of12 u-md-before2of12'
            : '')
        }
        style={{
          backgroundImage: `url(${window.AMPCOMPATH}images/facets/hero-facets-dark-midnight-blue.png)`
        }}
        data-testid={testId ? `${testId}-wrapper` : null}
      >
        <div className="ProspectRedesign-content u-posRelative u-sizeConstrained u-paddingVert6gu">
          <div
            className={
              'AdvisorProspect Grid' +
              (isSkinny
                ? ' u-lg-flexJustifyCenter u-md-flexJustifyCenter u-sm-paddingHoriz4gu u-sm-paddingVert4gu'
                : ' u-lg-padding4gu')
            }
          >
            <div className="ProspectRedesign-header u-lg-size5of12 u-lg-after1of12 u-flexAlignContentCenter">
              {prospectLabels.icon && (
                <BrandIcon
                  icon={prospectLabels.icon}
                  className="BrandIcon--sizeMedium u-paddingBottom2gu u-fillColorWhite u-md-hidden u-sm-hidden"
                  testId={testId}
                />
              )}
              <h3
                className={
                  'AdvisorProspect-heading u-textSecondary u-marginBottom4gu u-textColorWhite u-paddingRight2gu'
                }
                dangerouslySetInnerHTML={{ __html: prospectLabels.heading }}
              ></h3>
            </div>
            <div
              className={
                'AdvisorContent u-sm-sizeFull' +
                (isSkinny
                  ? ' u-lg-size10of12 u-md-size10of12'
                  : ' u-lg-size1of2')
              }
            >
              <div className={newFormClasses}>
                {!geoPermission && buttonTriggered && (
                  <div
                    className="Notification Notification--withIcon Notification--alert geolocation-denied u-marginBottom4gu"
                    aria-label="alert"
                  >
                    <div className="Notification-content u-textColorDefault">
                      Geolocation is temporarily unavailable. Please enter a ZIP
                      code instead.
                    </div>
                  </div>
                )}
                <div className="u-flex u-lg-flexRow u-md-flexRow u-sm-flexCol u-borderBottom">
                  <label
                    htmlFor="AdvisorProspect-input"
                    className="AdvisorProspect-zipLabel Input-label u-lg-size1of2 u-md-size1of2 u-textBold u-paddingTop1gu u-textSizeMsrp1 u-textDefault u-sm-marginBottom3gu u-paddingRight4gu u-sm-paddingRight0"
                  >
                    {prospectLabels.form}
                  </label>
                  <div className="u-flex u-sm-flexWrap">
                    <div className="u-flex u-flexCol u-sm-sizeFull">
                      <div className="Input-group">
                        <input
                          id="AdvisorProspect-input"
                          className={
                            'Input Input--floatingLabel' +
                            (isInvalidZip ? ' Input--error' : '')
                          }
                          placeholder=""
                          type="text"
                          ref={zipRef}
                          defaultValue={value}
                        />
                        <label
                          className="Input-label"
                          htmlFor="AdvisorProspect-input"
                        >
                          {prospectLabels.placeholder}
                        </label>
                      </div>
                      {isInvalidZip && (
                        <div
                          className="Notification--withIcon Notification--alert u-marginRightM8gu"
                          aria-label="alert"
                        >
                          <div className="Zip-notification u-textColorAlert u-textPrimary u-flex u-flexAlignItemsCenter u-marginTop1gu">
                            <span
                              className="adel-material-symbols u-textColorAlert u-marginRight2gu"
                              role="img"
                              title="warning"
                              aria-label="warning"
                            >
                              warning
                            </span>
                            Enter a valid 5-digit ZIP code
                          </div>
                        </div>
                      )}
                      <div
                        className="Notification--information"
                        aria-label="status"
                      >
                        <div className="Notification-content u-flexAlignItemsStart u-paddingLeft0">
                          <div className="Content">
                            <a
                              href="#"
                              onClick={event => {
                                event.preventDefault()
                                geoLocateUser(event)
                              }}
                              className="geolocate-me"
                              aria-label="Find my location and perform a search for advisors near me"
                            >
                              Find my location
                            </a>
                          </div>
                          {loader && (
                            <div className="u-posRelative u-marginLeft5gu u-marginTop2gu">
                              <div className="Loading u-posAbsoluteCenter u-textColorBlack">
                                <div className="Loading-indicator"></div>
                                <div className="Loading-indicator"></div>
                                <div className="Loading-indicator"></div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div>
                      <button
                        className="AdvisorProspect-button Button Button--primary u-marginLeft3gu u-sm-marginLeft0 u-sm-marginTop4gu u-sm-marginBottom4gu u-sm-sizeFull"
                        data-analytics={prospectLabels.analytics}
                        onClick={event => submitUpdateZip(event)}
                      >
                        {prospectLabels.button}
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  className={'u-marginTop4gu u-marginBottom2gu'}
                  dangerouslySetInnerHTML={{ __html: content.prospectContent }}
                />
              </div>
            </div>
          </div>
          <div
            className={
              'AdvisorProspect-content u-paddingTop2gu u-lg-size1of2 u-lg-before1of2'
            }
          >
            <div
              className="AdvisorProspect-disclosure Content--reversed"
              dangerouslySetInnerHTML={{ __html: content.disclosure }}
            />
          </div>
        </div>
      </div>
    )
  }

  // TODO remove return and make prior template default once old template is depreciated
  return (
    <div
      className={
        'Advisor--color Advisor--prospectView Content--reversed' +
        (isSkinny
          ? ' u-lg-size8of12 u-lg-before2of12 u-md-size8of12 u-md-before2of12'
          : '')
      }
      data-testid={testId ? `${testId}-container` : null}
    >
      <div className="u-sizeConstrained u-paddingVert10gu">
        <div
          className={
            'AdvisorProspect Grid' +
            (isSkinny
              ? ' u-lg-flexJustifyCenter u-md-flexJustifyCenter u-sm-paddingHoriz4gu u-sm-paddingVert4gu'
              : ' u-padding4gu')
          }
        >
          <div
            className={
              'AdvisorContent u-sm-sizeFull u-paddingRight4gu' +
              (isSkinny ? ' u-lg-size10of12 u-md-size10of12' : ' u-size1of2')
            }
          >
            <h2
              className={
                'AdvisorProspect-heading u-textSizeMsrp4 u-marginBottom4gu'
              }
              dangerouslySetInnerHTML={{ __html: prospectLabels.heading }}
            ></h2>
            <div className={formClasses}>
              <label
                htmlFor="AdvisorProspect-input"
                className="AdvisorProspect-zipLabel Input-label u-block u-textNormal u-sm-textSizeMsrp1 u-sm-marginBottom3gu"
              >
                {prospectLabels.form}
              </label>
              <div className="u-flex u-sm-flexWrap">
                <input
                  id="AdvisorProspect-input"
                  className="AdvisorProspect-input Input u-size2of3 u-sm-sizeFull u-sm-marginBottom3gu u-textColorLight"
                  type="text"
                  ref={zipRef}
                  placeholder={prospectLabels.placeholder}
                  defaultValue={state.value}
                />
                <button
                  className="AdvisorProspect-button Button Button Button--secondary Button--secondary--action-blue Button--reversed u-size1of3 u-marginLeft3gu u-sm-marginLeft0 u-sm-sizeAuto"
                  onClick={event => submitUpdateZip(event)}
                >
                  {prospectLabels.button}
                </button>
              </div>
              <div className="u-flex u-flexCol">
                <div className="Notification--information" aria-label="status">
                  <div className="Notification-content u-flexAlignItemsStart u-paddingLeft0">
                    <div className="Content">
                      <a
                        href="#"
                        onClick={event => {
                          event.preventDefault()
                          geoLocateUser(event)
                        }}
                        id="geolocate-go"
                        className="geolocate-me"
                        aria-label="Find my location and perform a search for advisors near me"
                      >
                        Find my location
                      </a>
                    </div>
                    {loader && (
                      <div className="u-posRelative u-marginLeft5gu u-marginTop2gu">
                        <div className="Loading u-posAbsoluteCenter u-textColorWhite">
                          <div className="Loading-indicator"></div>
                          <div className="Loading-indicator"></div>
                          <div className="Loading-indicator"></div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {!geoPermission && buttonTriggered && (
                  <div
                    className="Notification Notification--withIcon Notification--alert geolocation-denied"
                    aria-label="alert"
                  >
                    <div className="Notification-content u-textColorDefault">
                      Geolocation is temporarily unavailable. Please enter a ZIP
                      code instead.
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className={'AdvisorProspect-content'}>
              <div
                className={
                  !media ? 'u-marginBottom2gu' : '' + 'u-marginBottom2gu'
                }
                dangerouslySetInnerHTML={{ __html: content.prospectContent }}
              />
              <div
                className="AdvisorProspect-disclosure"
                dangerouslySetInnerHTML={{ __html: content.disclosure }}
              />
            </div>
          </div>

          {media ? (
            <div className="AdvisorMedia u-size1of2 u-sm-sizeFull u-sm-flexOrderFirst u-paddingLeft4gu u-sm-paddingLeft0 u-sm-paddingBottom4gu">
              {media}
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  )
}

Prospect.propTypes = {
  content: PropTypes.shape({
    template: PropTypes.string,
    prospectLabels: PropTypes.shape({
      icon: PropTypes.string,
      heading: PropTypes.string,
      form: PropTypes.string, // this is a input label
      placholder: PropTypes.string,
      button: PropTypes.string
    })
  }),
  testId: PropTypes.string
}

export default Prospect
