function addCookiesAnalytics() {
  document
    .getElementById('close-pc-btn-handler')
    .setAttribute('data-analytics', 'close-modal-btn')
  document
    .getElementsByClassName(
      'save-preference-btn-handler onetrust-close-btn-handler'
    )[0]
    .setAttribute('data-analytics', 'confirm-my-choices-btn')
  document
    .getElementById('ot-sdk-btn')
    .setAttribute('data-analytics', 'do-not-sell-or-share-my-personal-data-btn')
}

window.addEventListener('DOMContentLoaded', event => {
  const onetrustButton = document.getElementById('ot-sdk-btn')
  if (onetrustButton) {
    onetrustButton.addEventListener('click', addCookiesAnalytics)
  }
})
