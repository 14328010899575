import React from 'react'
import { FeatureFlagColor } from './FeatureFlagColor.type'

export type FeatureFlagProps = {
  backgroundColor?: FeatureFlagColor
  label?: string
  testId?: string
}

export const FeatureFlag = (props: FeatureFlagProps) => {
  const { backgroundColor, label, testId } = props

  let backgroundColorClass: string
  let textColorClass: string

  switch (backgroundColor) {
    case FeatureFlagColor.BERRY:
      backgroundColorClass = 'u-bgColorBerry'
      textColorClass = 'u-textColorWhite'
      break
    case FeatureFlagColor.LIME_LIGHT:
    default:
      backgroundColorClass = 'u-bgColorLime'
      textColorClass = 'u-textColorMidnightBlue'
      break
  }

  return (
    <div
      className={`Chip--ribbon Card--featureFlag ${backgroundColorClass}`}
      data-testid={testId || null}
    >
      <h6 className={`${textColorClass} u-textBold u-textUppercase`}>
        {label}
      </h6>
    </div>
  )
}
