import React from 'react'
import { BrandIconId } from '../BrandIcon/BrandIconId.type'
import { BrandIcon } from '../BrandIcon/BrandIcon'
import './style.module.scss'

export type SpotlightProps = {
  content: {
    // left
    iconLeft?: BrandIconId
    iconColorLeft?: string
    headingLeft?: string
    contentLeft?: string
    linkAnalyticsLeft?: string
    linkUrlLeft?: string
    linkTargetLeft?: string
    linkTextLeft?: string
    // right
    iconRight?: BrandIconId
    iconColorRight?: string
    headingRight?: string
    contentRight?: string
    linkAnalyticsRight?: string
    linkUrlRight?: string
    linkTargetRight?: string
    linkTextRight?: string
  }
  testId?: string
}

export const Spotlight = (props: SpotlightProps) => {
  const { content, testId } = props
  const {
    iconLeft,
    iconColorLeft,
    headingLeft,
    contentLeft,
    linkAnalyticsLeft,
    linkUrlLeft,
    linkTargetLeft,
    linkTextLeft,
    iconRight,
    iconColorRight,
    headingRight,
    contentRight,
    linkAnalyticsRight,
    linkUrlRight,
    linkTargetRight,
    linkTextRight
  } = content

  if (!headingLeft && !contentLeft && !headingRight && !contentRight) {
    return null
  }

  return (
    <div className="Spotlight u-sizeConstrained u-sm-paddingVert4gu u-md-paddingVert4gu u-paddingVert6gu">
      <div className="Grid">
        <div className="u-md-size1of2 u-lg-size1of2 u-sm-paddingRight0 u-md-paddingRight10gu u-paddingRight10gu u-sm-paddingBottom6gu u-md-paddingBottom6gu u-md-paddingTop6gu Spotlight-border">
          {iconLeft && (
            <BrandIcon
              icon={iconLeft}
              className={iconColorLeft}
              testId={testId ? `${testId}-icon-left` : undefined}
            />
          )}
          {headingLeft && (
            <div
              className="u-paddingTop4gu u-paddingBottom2gu"
              dangerouslySetInnerHTML={{ __html: headingLeft }}
              data-testid={testId ? `${testId}-heading-left` : null}
            />
          )}
          {contentLeft && (
            <div
              className="u-paddingBottom6gu"
              dangerouslySetInnerHTML={{ __html: contentLeft }}
              data-testid={testId ? `${testId}-content-left` : null}
            />
          )}
          {linkTextLeft && (
            <a
              className="Button Button--secondary Button--secondary--action-blue"
              data-analytics={linkAnalyticsLeft}
              href={`${linkUrlLeft}`}
              target={linkTargetLeft}
            >
              {linkTextLeft}
            </a>
          )}
        </div>
        <div className="u-md-size1of2 u-lg-size1of2 u-sm-paddingLeft0 u-md-paddingLeft10gu u-paddingLeft10gu u-sm-paddingTop6gu u-md-paddingTop6gu">
          {iconRight && (
            <BrandIcon
              icon={iconRight}
              className={iconColorRight}
              testId={testId ? `${testId}-icon-right` : undefined}
            />
          )}
          {headingRight && (
            <div
              className="u-paddingTop4gu u-paddingBottom2gu"
              dangerouslySetInnerHTML={{ __html: headingRight }}
              data-testid={testId ? `${testId}-heading-right` : null}
            />
          )}
          {contentRight && (
            <div
              className="u-paddingBottom6gu"
              dangerouslySetInnerHTML={{ __html: contentRight }}
              data-testid={testId ? `${testId}-content-right` : null}
            />
          )}
          {linkTextRight && (
            <a
              className="Button Button--secondary Button--secondary--action-blue"
              data-analytics={linkAnalyticsRight}
              href={`${linkUrlRight}`}
              target={linkTargetRight}
            >
              {linkTextRight}
            </a>
          )}
        </div>
      </div>
    </div>
  )
}
