import { CONSTs } from '../components/MarketWatch/views/utils'

export default function getStockQuotes(params) {
  if (!params) {
    return false
  }

  return fetch('/ods.svc/getMarketQuotes', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'OData-Version': '4.0',
      consumer: 'id=A0163796-61J0RSA3VPQ39ANK;type=WEB'
    },
    body: JSON.stringify(params.data)
  })
    .then(response => {
      return response.json()
    })
    .then(response => {
      if (!response || !response.value.identifiers) {
        return {
          response: response,
          message: response.error.code
        }
      }

      if (
        response.value.identifiers.length !== params.data.symbol.length ||
        response.value.explanations !== null
      ) {
        return {
          response: response,
          message: response.value.explanations
        }
      }

      return {
        response: response.value.identifiers,
        message: CONSTs.SUCCESS
      }
    })
    .catch(error => {
      console.error(error)
      return {
        response: error,
        message: error.message
      }
    })
}
