import { buildQuery } from '@cwp/utils'
import jsCookie from 'js-cookie'

function buildAdvisorSearchCrit(options) {
  const { search, zip } = options
  const criteria = {
    al: 0,
    aof: '',
    d: '',
    li: false,
    nrr: 10,
    rd: 0,
    se: '',
    sri: 0,
    st: zip ? 'zip code' : 'city, state',
    t: search || zip
  }

  return Object.keys(criteria)
    .map(key => `${key}:${criteria[key]}`)
    .join(';')
}

// Returns the new url with our new query parameter.
function updateQueryStringParameters(uri, key, value) {
  if (uri) {
    var re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i')
    var separator =
      uri && uri.indexOf('?') && uri.indexOf('?') !== -1 ? '&' : '?'
    if (uri.match(re)) {
      return uri.replace(re, '$1' + key + '=' + value + '$2')
    }
    return uri + separator + key + '=' + value
  }
}

// Gets the value for for lastAmpAuthState from the cookie.
export const getLastAmpAuthState = function () {
  if (window.location.hostname === 'localhost') {
    jsCookie.set('lastAmpAuthState', 'unrecognized')
  }
  const lastAmpAuthState = jsCookie.get('lastAmpAuthState')
  return lastAmpAuthState || 'unrecognized'
}

export function getAdvisorSearchUrl(options = {}) {
  const query = buildQuery({
    crit: `{${buildAdvisorSearchCrit(options)}}`,
    old: true,
    page: 0,
    view: 'list'
  })
  const urlWithQuery = `http://www.ameripriseadvisors.com/#search?${query}`

  const queryParameterName = 'lastAmpAuthState'
  const authState = getLastAmpAuthState()
  const urlWithAuthState = updateQueryStringParameters(
    urlWithQuery,
    queryParameterName,
    authState
  )

  // return url with custom query param
  return `${urlWithAuthState}`
}
