/* eslint-disable @typescript-eslint/no-namespace */
import React from 'react'
import { Spacer } from '../Spacer/Spacer'
import { BrandIcon } from '../BrandIcon/BrandIcon'
import { BrandIconId } from '../BrandIcon/BrandIconId.type'
import { PromoColor } from './PromoColor.type'
import { SpacerSpacing } from '../Spacer/SpacerSpacing.type'
import './style.module.scss'

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'promo-video-player': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >
      'promo-video-desc': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >
    }
  }
}

export type PromoProps = {
  content: {
    backgroundColor?: PromoColor
    isArticle?: string
    content?: string
    heading: string
    icon?: BrandIconId
    iconColor?: string
    image?: string
    imageMobile?: string
    iconImage?: string
    imageBorderColor?: string
    imageDesc?: string
    imageBorder?: string
    imagePosition?: string
    linkColor?: string
    linkText?: string
    linkTarget?: string
    linkAnalytics?: string
    linkUrl?: string
    superHeadline?: string
    transcript?: string
    audioTranscript?: string
    componentSpacing?: SpacerSpacing
    analytics?: string
    videoId?: string
  }
  testId?: string
}

export const Promo = (props: PromoProps) => {
  const { content, testId } = props
  const { componentSpacing } = content
  let backgroundColor

  switch (content.backgroundColor) {
    case 'purple-tint':
      backgroundColor = 'u-bgColorPurpleLight'
      break
    case 'mint-tint':
      backgroundColor = 'u-bgColorMintLight'
      break
    case 'lime-tint':
      backgroundColor = 'u-bgColorLimeLight'
      break
    case 'stone':
      backgroundColor = 'u-bgColorStone'
      break
    case 'transparent':
      backgroundColor = 'u-bgColorTransparent'
      break
    default:
      backgroundColor = 'white'
  }

  const getContentClasses = (
    imgPosition?: string,
    imgBgColor?: string,
    hasBorder?: string,
    isArticle?: string,
    hasVideo?: boolean
  ) => {
    const classList = []

    if (imgPosition === 'left') classList.push('u-flexOrderFirst')
    if (hasBorder === 'true') {
      classList.push('u-border')
    }
    if (imgBgColor === 'matchbackground') {
      switch (imgPosition) {
        case 'left':
          classList.push(
            'u-flexOrderFirst u-paddingLeft5gu u-paddingTop5gu u-paddingBottom5gu u-md-paddingBottom0 u-md-paddingHoriz5g u-sm-paddingBottom0 u-sm-paddingHoriz5gu'
          )
          break
        case 'right':
          classList.push(
            'u-paddingRight5gu u-paddingTop5gu u-paddingBottom5gu u-md-paddingHoriz5gu u-md-paddingBottom0 u-sm-paddingHoriz5gu u-sm-paddingBottom0'
          )
          break
      }
    }

    if (isArticle === 'true') {
      classList.push('u-lg-size4of12')
    } else if (hasVideo) {
      classList.push('u-lg-size8of12 u-bgColorBlack')
    } else classList.push('u-lg-size6of12')

    return classList.join(' ')
  }

  return (
    <Spacer spacing={componentSpacing}>
      <section
        className="Promo-redesign u-sizeConstrained"
        data-analytics-container="Promo"
      >
        <div className="u-flex u-flexAlignItemsStretch Grid u-flexAlignItemsCenter">
          <div
            data-testid={testId ? `${testId}-content` : null}
            className={`Promo-content u-flexAlignContentCenter u-paddingHoriz5gu ${backgroundColor} 
            ${
              content.isArticle == 'true' || content.videoId
                ? 'u-lg-size4of12'
                : 'u-lg-size6of12'
            } 
            ${
              content.isArticle === 'true' && content.imagePosition === 'right'
                ? 'u-lg-before2of12'
                : ''
            }`}
          >
            <div className="u-paddingTop6gu">
              {content.iconImage && (
                <img
                  src={`${content.iconImage}`}
                  alt=""
                  className="Promo-customIcon"
                  data-testid={testId ? `${testId}-customIcon` : null}
                />
              )}
              {content.icon && (
                <BrandIcon
                  icon={content.icon}
                  className={content.iconColor}
                  testId={testId ? `${testId}-icon` : undefined}
                />
              )}
              {content.superHeadline && (
                <p
                  className="Type-5 u-textEyebrow u-paddingTop4gu u-paddingBottom2gu"
                  data-testid={testId ? `${testId}-superHeadline` : null}
                >
                  {content.superHeadline}
                </p>
              )}
              {!content.videoId ? (
                <>
                  {content.heading && (
                    <h3
                      className="u-paddingBottom2gu"
                      data-testid={testId ? `${testId}-heading` : null}
                    >
                      {content.heading}
                    </h3>
                  )}
                  {content.content && (
                    <div
                      className="u-paddingBottom6gu"
                      data-testid={testId ? `${testId}-body` : null}
                      dangerouslySetInnerHTML={{ __html: content.content }}
                    ></div>
                  )}
                </>
              ) : (
                <promo-video-desc
                  className="video-container Content"
                  playlist-id={content.videoId}
                ></promo-video-desc>
              )}
              {content.transcript && (
                <a
                  className="Link u-block u-marginBottom6gu"
                  href={'/binaries' + content.transcript}
                >
                  Download transcript
                </a>
              )}
              {content.audioTranscript && (
                <a
                  className="Link u-block u-marginBottom6gu"
                  href={'/binaries' + content.audioTranscript}
                >
                  Download audio transcript
                </a>
              )}
              {content.linkUrl && (
                <a
                  className={`Button u-marginBottom6gu ${
                    content.linkColor === 'white'
                      ? 'Button--secondary'
                      : 'Button--primary'
                  }`}
                  href={content.linkUrl}
                  target={content.linkTarget}
                  data-analytics={content.linkAnalytics}
                  data-testid={testId ? `${testId}-button` : null}
                >
                  {content.linkText}
                </a>
              )}
            </div>
          </div>
          <div
            className={`u-flexAlignContentCenter Promo-content u-sm-flexOrderFirst u-md-flexOrderFirst ${getContentClasses(
              content.imagePosition,
              content.imageBorderColor,
              content.imageBorder,
              content.isArticle,
              content.videoId ? true : false
            )} ${backgroundColor} ${
              content.isArticle === 'true' && content.imagePosition === 'left'
                ? 'u-lg-before2of12'
                : ''
            }`}
          >
            {content.image && (
              <img
                className="Promo-image u-sm-hidden"
                src={content.image}
                data-testid={testId ? `${testId}-image` : null}
                alt={content.imageDesc}
              />
            )}
            {content.imageMobile && (
              <img
                className="Promo-image u-md-hidden u-lg-hidden"
                src={content.imageMobile}
                data-testid={testId ? `${testId}-image-mobile` : null}
                alt={content.imageDesc}
              />
            )}
            {content.videoId && (
              <promo-video-player
                className="video-container Content"
                playlist-id={content.videoId}
              ></promo-video-player>
            )}
          </div>
        </div>
      </section>
    </Spacer>
  )
}
