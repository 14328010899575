import React, { Fragment } from 'react'
import MicrositeFooter from '../../MicrositeFooter/index'

export default class Footer extends React.Component {
  render() {
    const { footerImgNorton } = this.props.content

    return (
      <Fragment>
        <MicrositeFooter nortonLogo={footerImgNorton} />
      </Fragment>
    )
  }
}
