import React from 'react'
import classes from './PersonalizedClasses'

const Image = props => {
  const logo = `${window.AMPCOMPATH}images/dac-missing-image.png`

  return (
    <img
      className={classes.image}
      alt={props.data.image ? props.data.name : 'Ameriprise Logo'}
      src={props.data.image || logo}
    />
  )
}

export default Image
