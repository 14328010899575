import React, { Component, Fragment } from 'react'
import classnames from 'classnames'
import '../views/index.css'

/*
 *  <InputField
 *  type='text' //Type could be anything, text, tel, email
 *  name='zipcode'
 *  id='zipcode'
 *  placeholder='zipcode'
 *  label='zipcode'
 *  reference={this.zipcodeRef} //React Refs
 *  error={errorFields.zipcode} //Set error true to display the message
 *  message={formErrorMessages.errorZipcode} //Error message to be passed on to display
 *  />
 */

/*
 * Input Component with error message
 */
// Component should be in class when we are using Refs
class InputField extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props)
  }
  render() {
    const {
      name,
      type,
      id,
      placeholder,
      reference,
      error,
      label,
      message,
      hideStyle,
      maxLength
    } = this.props
    const currentHideStyle = hideStyle || 'u-invisible'
    return (
      <Fragment>
        <label className={'Input-label'} htmlFor={id}>
          {label}
        </label>
        <div className="Input-group">
          <input
            className={classnames('Input', {
              'Input--error': error === true
            })}
            data-qa={'Input-' + id}
            name={name}
            id={id}
            placeholder={placeholder}
            type={type || 'text'}
            ref={reference}
            maxLength={maxLength}
          />
          {error && (
            <span
              className="Input-icon Icon"
              dangerouslySetInnerHTML={{ __html: '&#xE002' }}
            />
          )}
        </div>
        <ul
          className={
            'Input-errorList u-paddingTop1gu u-marginBottom2gu' +
            (error ? '' : ' ' + currentHideStyle)
          }
        >
          <li
            className="Input-errorListItem js-formError"
            data-qa={'InputError-' + id}
          >
            {message}
          </li>
        </ul>
      </Fragment>
    )
  }
}

export default InputField
