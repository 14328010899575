/*
 * Accordion Class
 */
class AmeripriseAccordion extends HTMLElement {
  connectedCallback() {
    const innerLinks = this.findInnerAccordionLinks()
    innerLinks.forEach(link => {
      link.addEventListener('click', e => this.handleInnerLink(e))
    })
  }
  findInnerAccordionLinks = () => {
    const hrefs = this.querySelectorAll('.Accordion-content a[href]')
    return Array.from(hrefs).filter(each => each.hash.includes('#inner'))
  }
  handleInnerLink(e) {
    e.preventDefault()
    const accordionHashElement = e.target.hash
    if (accordionHashElement) {
      const accordionLinkItem = this.querySelector(
        `a[name="${accordionHashElement.replace('#', '')}"]`
      )
      if (accordionLinkItem) {
        const accordionItem = accordionLinkItem.closest(
          'details.Content.Accordion-item'
        )
        accordionItem.setAttribute('open', '')
        accordionItem.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }
}

customElements.define('ameriprise-accordion', AmeripriseAccordion)

// accordions logic not specific to a particular instance
if (document.querySelector('ameriprise-accordion')) {
  closestPolyFill()
  handleView()
}

function handleView() {
  const hash = window.location.hash
  const hashedLink = document.querySelector(
    `a[name="${hash.replace('#', '')}"]`
  )
  if (hash && hashedLink) {
    openAccordionItem(hashedLink, el =>
      el.scrollIntoView({ behavior: 'smooth' })
    )
  } else {
    handleDefaultView()
  }
}

function openAccordionItem(linkElement, callback = () => {}) {
  if (linkElement) {
    const accordionItem = linkElement.closest('details.Content.Accordion-item')
    accordionItem.setAttribute('open', '')
    callback(accordionItem)
  }
}

function handleDefaultView() {
  const defaultAccordionItem = document.querySelector(
    '.Accordion-content a[name="default"]'
  )
  if (defaultAccordionItem) {
    openAccordionItem(defaultAccordionItem)
  } else {
    hideFirst()
  }
}

function hideFirst() {
  const disclaimer = document.querySelectorAll(
    'ameriprise-accordion .Disclaimer-text'
  )
  let accordions = document.querySelectorAll('ameriprise-accordion')

  if (disclaimer.length > 0) {
    disclaimer[0].closest('ameriprise-accordion').setAttribute('disclaimer', '')
    accordions = document.querySelectorAll(
      'ameriprise-accordion:not([disclaimer])'
    )
  }

  accordions.forEach((accordion, index) => {
    const item = accordion.querySelector('details.Accordion-item')
    const content = accordion.querySelector('.Accordion-content')

    if (item && content) {
      item.classList.remove('expanded')
      item.removeAttribute('open')
      content.setAttribute('aria-expanded', false)
    }
  })
}

function closestPolyFill() {
  if (!Element.prototype.matches) {
    Element.prototype.matches =
      Element.prototype.msMatchesSelector ||
      Element.prototype.webkitMatchesSelector
  }

  if (!Element.prototype.closest) {
    Element.prototype.closest = function (s) {
      var el = this

      do {
        if (Element.prototype.matches.call(el, s)) return el
        el = el.parentElement || el.parentNode
      } while (el !== null && el.nodeType === 1)
      return null
    }
  }
}

export { handleView, hideFirst, closestPolyFill }
