// Domain mapping / staging
const devMorningstarBaseUrl = 'https://awrduat.morningstar.com'
const devSamlBaseUrl = 'https://mni.qa.ameriprise.com'
const prodMorningstarBaseUrl = 'https://awrd.morningstar.com'
const prodSamlBaseUrl = 'https://mni.ameriprise.com'
const environments = [
  {
    name: 'dev',
    pattern: /^(http|https):?\/\/www\d*\.qa\.stage\.ameriprise.com\//,
    morningstarBaseUrl: devMorningstarBaseUrl,
    samlBaseUrl: devSamlBaseUrl
  },
  {
    name: 'qa',
    pattern: /^(http|https):?\/\/www\d*\.qa\.ameriprise.com\//,
    morningstarBaseUrl: devMorningstarBaseUrl,
    samlBaseUrl: devSamlBaseUrl
  },
  {
    name: 'prod',
    pattern: /^(http|https):?\/\/www\.ameriprise.com\//,
    morningstarBaseUrl: prodMorningstarBaseUrl,
    samlBaseUrl: prodSamlBaseUrl
  },
  {
    name: 'localhost',
    pattern: /^(http|https):?\/\/localhost/
  }
]

/**
 * getEnvironment
 */
export function getEnvironment(url = '') {
  url = url || window.location.toString()

  const env = environments.find(env => env.pattern.test(url))

  return (env && env.name) || 'prod'
}

/**
 * getEnvironmentUrls
 */
export function getEnvironmentUrls(name) {
  const env = environments.find(env => env.name === name) || {}

  const { morningstarBaseUrl, samlBaseUrl } = env
  return { morningstarBaseUrl, samlBaseUrl }
}
