import React from 'react'
import './Chevron.less'

const Chevron = props => {
  return (
    <i
      className={`u-bgColorTransparent mobile-view-chevron u-sm-grid1of12 ${
        props.hidden ? 'hidden' : ''
      }`}
    >
      <button
        id={props.id}
        aria-hidden="true"
        className="Icon u-textSizeMsrp3"
        onClick={props.handleChevronClick}
        disabled={props.hidden}
      >
        {props.children}
      </button>
      <span className="u-hiddenVisually">{props.description}</span>
    </i>
  )
}

export default Chevron
